@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');

* {
	margin: 0px;
	padding: 0px;
	font-family: 'Poppins', sans-serif;
}

body {
	font-family: 'Poppins', sans-serif;
	height: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html {
	width: 100%;
	height: 100%;
	font-family: 'Poppins', sans-serif;
}

/* ==========How It Works Section starts Here========== */
.uktcn-bg-top {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 50%;
	position: absolute;
	z-index: -1;
}
.uktcn-bg-bottom {
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 50%;
	position: absolute;
	z-index: -1;
}
.uktcn-heading-img {
	margin: 0 auto;
}
.uktcn-reg-dot {
	right: 0px;
	top: 100px;
	height: 12px;
	width: auto;
	z-index: -1;
}
.uktcn-img {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
}
.uktcn-num-div {
	background-image: url(/images/number-bg.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.uktcn-num-yellow-div {
	width: 8px;
	height: 8px;
	background: #fcb116;
	border-radius: 50%;
	flex:0 0 8px;
	margin-top: 5px;
}

/* Process Section Starts */
.uktcn-process-t-dot {
	left: 35px;
	top: 0px;
	height: 12px;
	width: auto;
	z-index: -1;
}
.uktcn-process-b-dot {
	left: 10px;
	bottom: 100px;
	height: 12px;
	width: auto;
	z-index: -1;
}

.uktcn-process ::before {
	content: '';
	background-image: url(/images/process-bg.png);
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	background-size: cover;
	right: 0px;
	bottom: 0px;
	height: 250px;
	width: 200px;
	z-index: -1;
}

/* Various Section Starts Here */

/* .uktcn-various ::before {
	content: '';
	background-image: url(../images/moon.png);
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	background-size: cover;
	right: 0px;
	bottom: -130px;
	height: 320px;
	width: 320px;
	z-index: 1;
} */

/* Payment Section Starts Here */
.uktcn-payment-bg {
	left: -73px;
	bottom: 25px;
	height: 250px;
	width: auto;
	z-index: -1;
}

/* Vocal Section Starts Here */

.uktcn-vocal-bg {
	left: -60px;
	bottom: 36px;
	height: 250px;
	width: auto;
	z-index: -1;
}

/* ======About Us Section======== */

.uktcn-vision ::before {
	content: '';
	background-image: url(/images/vision-arrow.png);
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	background-size: cover;
	left: 0px;
	bottom: 0px;
	height: 250px;
	width: 400px;
	z-index: -1;
}
/* three point section */
.uktcn-three-div {
	height: 80px;
	background: #181818;
	border-radius: 10px;
	border: 1px solid #404040;
	display: flex;
	flex-direction: row;
	justify-content: between;
	align-items: center;
	width: 100%;
}
/* carousel section starts here */
.uktcn-carousel-div {
	height: 276px;
}

/* ========Faq Section======== */
.uktcn-faq-bg-img {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
}
/* accordian section starts here */
.accordion-item {
	border-radius: 10px !important;
	background: transparent !important;
	border: 0px solid #404040;
}
.accordion-item:not(:first-of-type) {
	border: 0px solid #404040 !important;
}
.accordion-header {
	border-radius: 10px !important;
}
.accordion-button {
	padding: 14px !important;
	background: #2d2d2d !important;
	border-radius: 10px !important;
}
.accordion-button:focus, .accordion-button:active{
	outline: none !important;
	box-shadow: 0px 0px 0px !important;
}
.accordion-button .number{
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0px;
	color: #000000;
	border-radius: 50%;
	background-color: #f4b33f;
	margin-right: 15px;
	line-height: 19px;
    font-weight: 500;
    font-size: 14px;
}
.accordion-button::after {
	width: 2rem !important;
	height: 2rem !important;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 1rem !important;
	transform: rotate(90deg);
}
.accordion-button:not(.collapsed) {
	border-radius: 10px !important;
	background-color: #2d2d2d !important;
	color: #181818 !important;
}
.accordion-body {
	padding: 14px !important;
	margin-top: 5px;
	background-color: #2d2d2d;
	border-radius: 10px;
}
.accordion-body p{
	margin-bottom: 0px;
}

/* Franchise Section Starts Here */
/* .uktcn-franchise-dv {
	background-image: url(/images/franchise-banner.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 296px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
} */
.uktcn-franchise-dv img{
	border:1px solid #fff;
	border-radius: 5px;
}
.uktcn-submit {
	background: #fcb116;
	border: 1px solid #fcb116;
}




.uktcn-form-bg {
	background-color: #1b1b1b;
	border: 1px solid #404040;
	border-radius: 10px;
}
.uktcn-absolute-icn {
	position: absolute;
	right: 15px;
	top: 40px;
}


.uktcn-form-bg .form-label {
	color: #ffffff;
}
.uktcn-form-bg .form-control {
	background: #181818 !important;
	border: 1px solid #404040 !important;
	border-radius: 0.75rem !important;
}
.uktcn-form-bg .form-control:focus {
	box-shadow: none !important;
	border: 1px solid #fcb116 !important;
} 

.extra-pad {
	padding-left: 100px;
	padding-right: 100px;
}

/* Media Query starts here */
@media only screen and (max-width: 1023px) {
}
@media only screen and (max-width: 992px) {
	.uktcn-process ::before {
		display: none;
	}
	.uktcn-various ::before {
		display: none;
	}
	.uktcn-vision ::before {
		display: none;
	}
}
@media only screen and (max-width: 767px) {
	.uktcn-resp-pad {
		padding: 48px 10px 0px 10px;
	}
	.uktcn-img {
		display: block;
		margin: 0 auto;
	}
}
@media only screen and (max-width: 576px) {
	.extra-pad {
		padding-left: 15px;
		padding-right: 15px;
	}
	.uktcn-three-div {
		height: 145px;
	}
	.owl-carousel .owl-stage-outer {
		height: auto;
	}
}
@media only screen and (max-width: 375px) {
	.uktcn-img {
		width: 300px;
	}
}

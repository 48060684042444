/* Customed by Sayantan Bhattacharjee */

/* All Customised font-size */

.font-13 {
	font-size: 13px;
}

/* all Customised Z-index */

.z {
	z-index: 1;
}
.z-1 {
	z-index: 1;
}

/* All customised color */

.white {
	color: #ffffff !important;
}
.blk {
	color: #0f1012 !important;
}
.yellow {
	color: #fcb116 !important;
}
.purp {
	color: #462364 !important;
}

/* All Customised Backgrounds */

.bg-blk {
	background: #0f1012 !important;
}
.bg-yellow {
	background-color: #fcb116 !important;
}
.bg-purp {
	background-color: #462364 !important;
}

/* All Customised Button */

.btn-purp {
	background-color: #462364 !important;
	border: 1px solid #462364 !important;
	color: #ffffff;
}
.btn-purp:hover {
	color: #462364 !important;
	background-color: #ffffff !important;
}

.btn-outline {
	border: 1px solid #d61865 !important;
	background-color: #ffffff !important;
	color: #d61865 !important;
}
.btn-outline:hover {
	background-color: #d61865 !important;
	color: #ffffff !important;
}

/* =======Bootstrap Customization======= */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
/* @import url('https://fonts.googleapis.com'); */
/* @import url('https://fonts.gstatic.com'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
/* @import url('https://fonts.googleapis.com'); */
@import url('https://fonts.gstatic.com" crossorigin');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;700;800;900&display=swap');
@font-face {
  font-family: 'Cooper';
  src: url('/fonts/Cooper.eot');
  src: url('/fonts/Cooper.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Cooper.woff2') format('woff2'),
      url('/fonts/Cooper.woff') format('woff'),
      url('/fonts/Cooper.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
.heading h1{
  font-family: 'Cooper';
  font-size: 24px;
  color: #ffbc07;
  /* -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #ffbc07; */
  /* -webkit-text-stroke: 2px #ffbc07; */
  /* paint-order: stroke fill; */
  /* font-weight: 600; */
}



body {
  background-color: #000000 !important;
  font-family: 'Roboto', sans-serif;
}

p {

  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 400;

}
a{
  text-decoration: none !important;
}

.search-box{
	/* position: absolute;
	top:50px;
	transform: translate(-36%,-50%);
	height: 60px;
	border-radius: 50px;
	padding: 10px; */
}

.search-box:hover .search-txt{
	width: 200px;
	padding: 0 6px;
}


.accordion-button:not(.collapsed)::after {
  
  font-family: 'FontAwesome';
  content: "\f107" !important;
  color: #fff;

  background-image:none;
  text-align: center;
  line-height: 32px;
  font-size: 21px;
  transform: rotate(0);


}


.accordion-button::after {

  font-family: 'FontAwesome';
  content: "\f105" !important;
  color: #fff;
  background-image:none !important;
  text-align: center;
  line-height: 32px;
  font-size: 21px;

  background-color: transparent !important;
  border:1px solid #f4b33f;
  color: #f4b33f;
  

}


.search-btn{
	float: right;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

}

.search-btn i{  font-size: 40px; color: #fcb116;}

.search-txt{
	border:none;
	outline: none;
	background: none;
	float: left;
	padding: 0;
	color:#fff;
	font-size: 16px;
	line-height: 40px;
	width: 0;
	transition: width 400ms;

}


.btn-primary {

    background-color: #462364 !important;
    border: 1px solid  #462364 !important;
    font-size: 14px;

}

.btn-primary i { margin-right: 5px;}

.btn {

    border-radius: 28px !important;
}


header span {

    color: #fff !important;
}
header{padding-top: 15px; padding-bottom: 20px;}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    text-align:center;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }


  .bg-dark {
    background-color: #000!important;
}

.list-sec::before {
  width: 94%;
  margin-top: 3px;
  display: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.2;
  
}


.list-sec {


 height: auto;

}

.no_sec {
  background: url(/images/bg1.svg) no-repeat;
  width: 21px;
  height: 41px;
  text-align: center;
  float: left;
  background-size: cover;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.no_sec h3 {
  line-height: inherit;
    font-size: 21px;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: #fff;
   
}


/* .no_sec::before {
  background: url(/images/line.png) repeat-x;
  position: absolute;
  z-index: auto;
  content: "";
  height: 2px;
  width: 30px;
  margin-top: 26px;
  opacity: .8;
  margin-left: -14px;
} */


.lt {

  padding-left: 0px;

}


.navbar-light .navbar-toggler-icon {
  background-image:none;
}

.navbar-light .navbar-toggler {

  border: none;
}


.list-sec::before {
  background: url(/images/line.png) repeat-x;
  position: absolute;
  z-index: auto;
  content: "";
  height: 2px;
  width: 1117px;
  margin-top: 17px;
  opacity: .8;
}


.sec2 h3 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 15px;
  padding-left: 21px;
  font-size: 30px;
  text-transform: uppercase;
}


.sec3 h3 {
  margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 16px;
    padding-left: 38px;
    font-size: 30px;
    text-transform: uppercase;
}


.sec4 h3 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 15px;
    margin-right: -9px !important;
    font-size: 30px;
    text-transform: uppercase;
    text-align: right;
}
.text-success {

  color: #0ca41b !important;

}


@media (min-width: 1400px) {

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1312px;
}

}




.sidenav a::before {

    background: none !important;
    content: "";

}


footer {

  background: #2d2d2d;
  font-family: 'Poppins', sans-serif;

}
footer p{
  color: #ffffff;
}
footer small{color: #ffffff;}
#custom-search-input{
  padding: 5px;
  border-radius: 6px;
  background-color: #d8d8d8;
  border-radius: 5px;
  background: #d8d8d8 !important;
  box-shadow: none;
  opacity: 1;
}
.phone_number{color: #ffffff; font-size: 16px;}
footer .nav-links{
  color: #f4b33f;
  font-size: 18px;
  padding: 0px;
  font-weight: 500;
  display: inline-block;
}
footer h3{
  color: #ffffff;
}
footer input:-webkit-autofill, footer input:-webkit-autofill:hover{
  background: #d8d8d8 ;
}
#custom-search-input input{
  border: 0;
  box-shadow: none;
  border-radius: 20px;
  background: transparent !important;
}

#custom-search-input button{
  margin: 2px 0 0 0;
  background: none;
  box-shadow: none;
  border: 0;
  color: #462364;
  padding: 0 8px 0 10px;

}

#custom-search-input button:hover{
  border: 0;
  box-shadow: none;


}

#custom-search-input .glyphicon-search{
  font-size: 23px;
}

.social-icons-ql{
  padding: 0px;
  margin: 0px;
  display: flex;
}
.social-icons-ql li{
  list-style:none;
  text-align:center;
  margin-right: 40px;
}
.social-icons-ql li:last-child{
  margin-right: 0px;
}
.social-icons-ql li a{
  /* background:#3b5998; */
}

.social-icons-ql li:last-child{margin-right: 0px;}
   .social-icons-ql li:nth-child(2) a{
/* background:#25B8E6; */
}

.social-icons-ql li:nth-child(3)a {
/* background:rgba(0, 122, 181, 1); */
}
.social-icons-ql li:nth-child(4) a{
background:#DC4A38;
}
.social-icons-ql li:nth-child(5) a{
background:#CC2028;
}

.social-icons-ql li:nth-child(6) a{
background:#E05929;
}
.social-icons-ql li:nth-child(7) {
background:#EB4924;
}



.social-icons-ql li i{
  color:#fff;
  font-size:40px;
}

.text-primary {

  color: #462263 !important;

}

.bg-sec {

  background: #000 center center;
  z-index: -1;

}


.bg-sec_inner {

  /* background:url(/images/skybg.png) no-repeat #0f1012 top  center; */
  z-index:1;
  background-size: 100%;

}

.list-head h4 {

    margin-top: 0px;
    font-size: 18px;margin-bottom: 0px;

}


.mobile_footer {
  background: #2d2d2d;
  border-radius: 5px;
  margin-top: 0;
  overflow: hidden;
  height: 60px;
  position: fixed;
  bottom: 0;
  width: 90%;
  z-index: 999;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  margin-bottom: 20px;
  
}
.mobile_footer .mobile_footer_section{
  height:100%;
}
.mobile_footer .mobile_footer_section a{
  font-size: 22px;
  color: #ffffff;
  width: 100% !important;
  flex: 0 0 100%;
  display: inline-block;
  height: 100%;
  text-transform: uppercase;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3px 0px !important;
}
.mobile_footer .mobile_footer_section a span{
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #ffffff;
  margin-top: -6px;
}
.mobile_footer .mobile_footer_section a i{
  font-size: 18px;
  margin-right: 0px;
}
.mobile_footer .mobile_footer_section a:hover{
  color: #ffc107;
}
.mobile_footer .mobile_footer_section a:hover span{
  color: #ffc107;
}
.mobile_footer .active a{
  color: #ffc107;
}
.mobile_footer .active a span{
  color: #ffc107;
}
.mobile_footer .desibled i{color: #dddddd;}
.mobile_footer .mobile_footer_section a:hover svg path{
  fill:#ffc107;
}
.lrf.Aclass span{
  color: #ffc107 !important;
}
.lrf.Aclass i{
  color: #ffc107 !important;
}
.lrf span{
  color: #ffffff !important;
}
.lrf i{
  color: #ffffff !important;
}
.mobile_footer .active a svg path{
  fill:#ffc107;
}

.sublogin{
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0; left: 0px;
  background-color: #fff;
  height: 0px;
  border-radius: 35px 35px 0px 0px;
  overflow: hidden;
  display: none;
  transition:0.5s;
  z-index: 999;
}
.sublogin.addClass{
  height: 205px;
}
.offer-list{
  padding-bottom: 90px;
}
.list{
  padding: 0px;
  margin: 0px;
}
.list .uktcn-num-yellow-div.d-flex.justify-content-center.align-items-center{
  margin-right: 15px;
}
.list p{color: #ffffff;}
.list {list-style: none;}
.list ul{padding-left: 50px;}
.list ul li{color: #ffffff;}

.sublogin ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.sublogin ul li{text-align: center; border-bottom: 1px solid #f1f1f1;}
.sublogin ul li a{font-size: 16px; color: #000000; text-decoration: none; padding: 10px 0px; display: inline-block; width: 100%; text-transform: uppercase;}
.sublogin ul li a:hover{background-color: #ffc107; color: #000;}
.m_f i {
  font-size: 30px;
  line-height: 43px;
  padding-bottom: 15px;
}
.m_f a {

   color:#000;

}

.m_f a:hover {

  color:#fff;

}

.m_f:hover {

  background: #fcb019;
  color: #fff !important;
  height: 75px;

  

}

.notification{
  background: #fff;
    position: fixed;
    right: 10px;
    top: 79px;
    border-radius: 5px;
    z-index: 9;
    width: 29%;
    box-shadow: 0px 10px 20px #00000017;
}
.notification .icon{
  background: rgb(255 193 7 / 12%);
    /* transform: translate(10px, 10px); */
    flex: 0 0 7%;
    text-align: center;
    margin-right: 10px;
}
.notification .icon i{
  color: #ffc107;
  font-size: 17px;
  transform: translate(0%, 190%);
}
.noti-text{ flex:0 0 80%; padding: 10px 0px;}
.noti-text h3{font-size: 16px; margin-bottom: 0px; color: #462364; margin-bottom: 5px;}
.noti-text p{font-size: 13px; color: #818181; margin-bottom: 0px; line-height: 20px;}
.noti-text p .noti-enb{
  color: #462364;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    /* margin-top: 5px; */
    font-size: 14px;
    padding: 0;
}

.noti-text p .noti-enb:focus {
  outline: none;
  box-shadow: none;
  color:blue;
}

.notification .close{
  flex: 0 0 10%;
}
.notification .close a{
  padding: 7px 7px;
  display: inline-block;
  float: right;
  color: #000000;
}

 @media (max-width: 1400px) {

  .sec3 h3 {
   
      padding-left: 66px;
 
  }

  .lt {

    padding-left: 0px !important;
  }

  .sec2 h3 {
   
    letter-spacing: 14px;
  
}

.sec4 h3 {

  margin-right: -13px !important;
  
}

} 



.bg-sec2 {

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fff1ca+0,ffffff+41 */
background: #fff1ca; /* Old browsers */
background: -moz-linear-gradient(top,  #fff1ca 0%, #ffffff 41%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #fff1ca 0%,#ffffff 41%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #fff1ca 0%,#ffffff 41%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff1ca', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */





}







.search-box2{
	position: absolute;
	top:39px;
	transform: translate(0%,-50%);
	height: 60px;
	border-radius: 50px;
	padding: 10px;
  right: 162px;
  cursor: pointer;
}

.search-box2:hover .search-txt{
	width: 200px;
	padding: 0 6px;
  width: 210px;
    padding: 0 6px;
    margin-left: -169px;
    position: absolute;
    z-index: -4;
    padding-left: 12px;
  
}

.sec_search {

  background-color: #fff;
  border-radius:20px;

}


/* .search-box2:hover .search-txt {
  width: 210px;
  padding: 0 6px;
  margin-left: -169px;
  position: absolute;
  z-index: -4;
  padding-left: 12px;
} */


.search-btn2{
	float: right;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

}

.search-btn2 i{  font-size: 30px; color: #462364;}

.search-txt2{
	border:none;
	outline: none;
	background: none;
	float: left;
	padding: 0;
	color:#000;
	font-size: 16px;
	line-height: 40px;
	width: 0;
	transition: width 400ms;

}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}




.banner form input {
  border-radius: 50px;
  padding: 12px 17px;
  background: transparent;
  border-width: 0 0 1px 0;
  box-shadow: none;
  color: #000;
  font-weight: normal;
  font-size: 16px;
  background-color: #fff;
  z-index: 99;
  height: 50px;
  border: 0;
}

.form-group {

  width: 100%;
}


.banner form button {
  width: 152px;
  height: 50px;
  padding: 0;
  margin: 0;
  background: #462364!important;
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 39px;
  color: #fff;
  text-align: center;
  display: block;
  float: right;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  border: solid 2px #fff;
  margin-top: -50px;
}

.work img {
  box-shadow: -1px 5px 14px 4px rgba(0,0,0,0.1);
  -webkit-box-shadow: -1px 5px 14px 4px rgba(0,0,0,0.1);
  -moz-box-shadow: -1px 5px 14px 4px rgba(0,0,0,0.1);
  border-radius: 100%;
  margin-bottom: 50px;
  background: white;
  width: 114px;
  height: 107px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: .5rem;
  font-family: 'Poppins', sans-serif;
}

.star-rating i {
	color: #ffc50c;
	font-size: 15px;

}


.map iframe {
  border-radius: 30px;
  filter: grayscale(100%);
}



.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 119%;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 10!important;
  transition: opacity .15s ease;
  left: -211px;
  background: #fff;
  width: 91px;
  height: 52px;
  border-radius: 11px;
}

.carousel-control-next {
  margin-left: 41px;
}


.carousel-control-next-icon {
  background: url(/images/arrow.png) no-repeat;
  margin-top: 8px;
  margin-left: 57px;
}

.carousel-control-prev-icon {

  background: url(/images/arrow2.png) no-repeat;
  margin: 9px 0 0 -24px;

}

.btn-primary i {
 
    font-size: 20px;

}


.text-danger {
  color: #e31328!important;
}

.owl-carousel .owl-nav [class*='owl-'] {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.owl-carousel .owl-nav [class*='owl-'].disabled:hover {
  background-color: #D6D6D6;
}
.owl-carousel {
  /* position: relative; */
  display: block !important;

}
.owl-carousel .owl-nav{display: block;}
.owl-carousel .owl-next,
.owl-carousel .owl-prev {
  width: 40px;
  height: 40px;
	line-height: 50px;
	border-radius: 50%;
  position: absolute;
  top: 25%;
	font-size: 20px;
  color: #fff;
	text-align: center;
  box-shadow: 0 0 20px -10px rgb(0 0 0 / 80%);
  background-color: #ffffff !important;

  display: flex;
align-items: center;
justify-content: center;

}

.owl-carousel .owl-next span,
.owl-carousel .owl-prev span{
  font-size: 34px;
  line-height: 30px;
  display: inline-block;
  padding-top: 0px;
}

.innerbanner .item .image{border: 1px solid #fff;}
#search_form input:focus{outline: none !important;}
.owl-nav .owl-prev {
  left: -23px !important;
  top: 100px !important;
}

.owl-nav .owl-next {
  right: -23px !important;
  top: 100px !important;
}

.btn-warning {
  color: #000;
  background-color: #f4b33f !important;
  border-color: #f4b33f !important;
}
.yellow-color{
  color: #f4b33f;
}
.red-color{
	color: red;
}



.cart-wrap {
	padding: 40px 0;
	font-family: 'Open Sans', sans-serif;
}
.main-heading {
	font-size: 19px;
	margin-bottom: 20px;
}
.table-cart table {
    width: 100%;
}
.table-cart thead {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 5px;
}
.table-cart thead tr th {
    padding: 8px 0 18px;
    color: #484848;
    font-size: 15px;
    font-weight: 400;
}
.table-cart tr td {
    padding: 40px 0 27px;
    vertical-align: middle;
}
.table-cart tr td:nth-child(1) {
    width: 52%;
}
.table-cart tr td:nth-child(2) {
    width: 26%;
}
.table-cart tr td:nth-child(3) {
    width: 13.333%;
}
.table-cart tr td:nth-child(4) {
    width: 8.667%;
    text-align: right;
}
.table-cart tr td .img-product {
    width: 72px;
    float: left;
    margin-left: 8px;
    margin-right: 31px;
    line-height: 63px;
}
.table-cart tr td .img-product img {
	width: 100%;
}
.table-cart tr td .name-product {
    font-size: 15px;
    color: #484848;
    padding-top: 8px;
    line-height: 24px;
    width: 50%;
}
.table-cart tr td .price {
    text-align: right;
    line-height: 64px;
    margin-right: 40px;
    color: #989898;
    font-size: 16px;
    font-family: 'Nunito';
}
.table-cart tr td .quanlity {
    position: relative;
}
.product-count .qtyminus,
.product-count .qtyplus {
	width: 34px;
    height: 34px;
    background: transparent;
    text-align: center;
    font-size: 19px;
    line-height: 34px;
    color: #000;
    cursor: pointer;
    font-weight: 600;
    background-color: #fcb116;
    border-radius: 50% !important;
}
.product-count .qtyminus {
    line-height: 32px;
}
.product-count .qtyminus {
	border-radius: 3px 0 0 3px; 
}
.product-count .qtyplus {
	border-radius: 0 3px 3px 0; 
}
.product-count .qty {
	width: 50px;
	text-align: center;
	border: none;
  font-size: 18px;
}
.count-inlineflex {
	display: inline-flex;
	border-radius: 20px;  
  margin-left:10px;
}
.total {
	font-size: 24px;
	font-weight: 600;
	color: #8660e9;
}
.display-flex {
	display: flex;
}
.align-center {
	align-items: center;
}



.product-count button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #000;
  color: #fff;
}

.qty2 {

    background: #181818 !important;

}

.coupon-box {
    padding: 63px 0 58px;
    text-align: center;
    border: 2px dotted #e5e5e5;
    border-radius: 10px;
    margin-top: 55px;
}
.coupon-box form input {
    display: inline-block;
    width: 264px;
    margin-right: 13px;
    height: 44px;
    border-radius: 25px;
    border: solid 2px #cccccc;
    padding: 5px 15px;
    font-size: 14px;
}
input:focus {
	outline: none;
	box-shadow: none;
}
.round-black-btn {
	border-radius: 25px;
    background: #212529;
    color: #fff;
    padding: 8px 35px;
    display: inline-block;
    border: solid 2px #212529; 
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
}
.round-black-btn:hover,
.round-black-btn:focus {
	background: transparent;
	color: #212529;
	text-decoration: none;
}
.cart-totals {
	border-radius: 3px;
	background: #e7e7e7;
	padding: 25px;
}
.cart-totals h3 {
	font-size: 19px;
    color: #3c3c3c;
    letter-spacing: 1px;
    font-weight: 500;
}
.cart-totals table {
	width: 100%;
}
.cart-totals table tr th,
.cart-totals table tr td {
	width: 50%;
    padding: 3px 0;
    vertical-align: middle;
}
.cart-totals table tr td:last-child {
	text-align: right;
}
.cart-totals table tr td.subtotal {
	font-size: 20px;
    color: #6f6f6f;
}
.cart-totals table tr td.free-shipping {
	font-size: 14px;
    color: #6f6f6f;
}
.cart-totals table tr.total-row td {
	padding-top: 25px;
}
.cart-totals table tr td.price-total {   
	font-size: 24px;
    font-weight: 600;
    color: #8660e9;
}
.btn-cart-totals {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 20px;
}
.btn-cart-totals .round-black-btn {
	margin: 10px 0; 
}

.rounded {
  border-radius: 8px!important;
}

.clint_img img {

  border-radius: 50%;
    width: 70px;
    height: 70px;

}




.border {
  /* border: 1px solid #3d3d3d!important; */
}

.bg-dark {
  background-color: #181818!important;
}

.reviewlist .bg-dark:hover {

  box-shadow: 0px 1px 32px 0px rgba(138,100,16,0.77);
-webkit-box-shadow: 0px 1px 32px 0px rgba(138,100,16,0.77);
-moz-box-shadow: 0px 1px 32px 0px rgba(138,100,16,0.77);

}
.navbar{
  width: 100%;
}
.navbar-light .navbar-nav .nav-link {

    color: #fff;
    font-size: 17px;

}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {

  color: #fcb116;
}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fcb116;
}



.spansec {

  line-height: 36px;
  height: 37px;
  float: left;
  margin-bottom:10px ;
}

.ingradients {

  /* height: 81px; */

}

.modal-dialog {
  max-width: 500px;
  margin:9rem auto;
  
  
}

.modal-content {

  border-radius: 1.3rem !important;
  padding: 20px;
  

}

.modal-backdrop {
  z-index: auto;
}

.modal-header {

  border: none;
}


.modal-footer {

  border: none;
}

.modal-content  .form-control {

  box-shadow: 0px 0px 13px -4px rgba(0,0,0,0.42);
-webkit-box-shadow: 0px 0px 13px -4px rgba(0,0,0,0.42);
-moz-box-shadow: 0px 0px 13px -4px rgba(0,0,0,0.42);
border-radius: 50px;

}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da ;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* border-radius: .25rem; */
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



  


.password {
  /* background: url(/images/password.png) no-repeat; */
  background-position: 97% 11px;
}

a {

  text-decoration: none;
}

.modal-header .btn-close {
  padding: 16px !important;
  border-radius: 49% !important;
  margin-top: -37px;
}



.container-list {

  width: 907px;
  margin: 0 auto;
}
.price2 {

  width: 115px;
}

.origin {

  width: 341px;

}

.destination {

  /* width: 211px; */
  /* width: 74%; */

}

.bg-primary {

  background: #462364;

}

.bg-primary {

  background: #462263 !important;

}

.border-bottom {
  border-bottom: 1px solid #696969!important;
}


.border-top {
  border-top: 1px solid #696969!important;
}

.check_dropdown {
  background: none !important;
  border: 1px solid #696969!important;
  border-radius: 10px;
  color: #fff !important;
  padding: 9px 12px;
}

.fd::before {
  font-family: 'FontAwesome';
  content: "\f107";
  position: absolute;
  z-index: 9;
  color: #ffab00;
  right: 41%;
  font-size: 20px;
  margin-top: 5px;
}


option { 

background: #000;

}


.check_dropdown::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.check_dropdown::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
.check_dropdown:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
.check_dropdown:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}


.form-select {
  background: url(/images/dropdown.png) no-repeat;
  background-position: 95% 15px;


  border: 1px solid #696969!important;
  border-radius: 10px;
  color: #fff !important;
  padding: 9px 12px;


}





.form-check-input:checked {
  background-color: #74ba18;
  border-color: #74ba18;
}



.modal-dialog2 {
  max-width: 744px;
  margin: 11.75rem auto;
}


#exampleModalnew .form-check-input[type=checkbox] {
  border-radius: 1.25em;
}


#exampleModalnew .form-check-input {
  width: 1.3em;
  height: 1.3em;

}



#exampleModalnew  .border {
  border: 1px solid #d9d9d9!important;
}


#exampleModalnew  .border:hover {
  
  background: #fbfff5;
  border:1px solid #fff !important;
  box-shadow: -3px 1px 24px -15px rgba(0,0,0,0.72);
-webkit-box-shadow: -3px 1px 24px -15px rgba(0,0,0,0.72);
-moz-box-shadow: -3px 1px 24px -15px rgba(0,0,0,0.72);
  

}


.btn-outline-primary {
  color: #462364;
  border-color: #462364;
}


.btn-outline-primary:hover {
  color: #fff;
  background-color: #462364;
  border-color: #462364;
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #462364;
  border-color: #462364;
}


.form-control:focus {
  color: #fff;
  background-color: #462364;
  border-color: #fff !important;
  outline: 0;
  
}


.email:focus {

 background-image: url(/images/mail2.png);
 background-repeat: no-repeat;

}


.password:focus {

  /* background-image: url(/images/pass2.png); */
  background-repeat: no-repeat;
 
 }
 .reset{
   color: #ffab00 !important;
   font-weight: 500 !important;
 }


 
.name:focus {

  background-image: url(/images/user2.png);
  background-repeat: no-repeat;
 
 }
 

.food_brand {
  position: fixed;
  right: 0;
  width: 206px;
  height: 47px;
  background: #fff;
  top: 20%;
  padding: 5px;
  border-radius: 20px 0 0 20px;
  z-index: 8;
  padding: 9px 15px;
  font-weight: 500;
  font-size: 18px;
}

.width_productss {

  width: 469px;
  border-color: #ffffff38 !important;
  padding: 8px 0px !important;
}
.price{
  color: #ffab00;
}
.refresh-contaner .product-count{
  border-color: #ffffff38 !important;
}
/* .ingradients{color:#8a8885; } */
.ingradients span{color: #9e9e9e; border:1px solid #9e9e9e; display: inline-block;}
.ingradients p{margin-bottom: 0px; margin-top: 0px;}
.ingradients{margin-bottom: 25px;}
.ingradients-title{color: #fbaf16;}
.review h4{color:#ffab00;}
.search_filter{
  border:1px solid #462364;
  background-color: transparent;
  padding: 5px 15px;
  margin-right: 10px;
  border-radius: 35px;
}
.search_filter:last-child{
  margin-right: 0px;
}
.search_filter.active{
  color: #fff;
  background-color: #462364;
  /* border-color: #462364; */
}
.food {

  width: 100%;
  height: 100%;

}


text-muted {
  color: #a5a5a5!important;
}
.inner-heading{
  margin-bottom: 50px;
}


.inner-heading h2 {
  position: relative;
  font-size: 35px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.inner-heading p{color: #fff;}
.inner-heading img{margin-right: 15px;}
.contact_sec .contact-form{border:1px solid rgba(255,255,255,0.2); background-color: #181818!important;}
.contact_sec .contact-form textarea{background-color: transparent !important; }
.contact_sec .contact-form .form-control{color: #ffffff;}
.contact_sec .contact-form .form-control:before, .contact_sec .contact-form .form-control:after{
  display: none;
}
.navbar-toggler{display: none;}
.contact-form input{padding-right: 35px;}
.contact-form textarea{padding-right: 35px;}
#registeruser input{padding-right: 55px;}
#login input{padding-right: 55px;}

.name2 {
  background: url(/images/man.png) no-repeat !important;
  background-position: 97% 11px !important;
  border: 1px solid #636363;
}


.phone2 {
  background: url(/images/phone.png) no-repeat !important;
  background-position: 97% 11px !important;
  border: 1px solid #636363;
}


.email2 {
  background: url(/images/mail.png) no-repeat !important;
  background-position: 97% 11px !important;
  border: 1px solid #636363;
}

.massage {
  background: url(/images/massage.png) no-repeat  !important;
  background-position: 98% 11px !important;
  border: 1px solid #636363;
  background-color: #181818 !important;
  color: #fff !important;
}


.air_bg {

   background: url(/images/airport.png) no-repeat;
   background-size:100% ;
   background-position: bottom center;


}


.air_bg2 {

  background: url(/images/airport.png) no-repeat;
  background-size:100% ;
  background-position: 0 36%;
  

}

.uktcn-vision {

  position: relative;
}

 .about_last .owl-prev {

  display: none !important;
}
.about_last .image{margin-right: 5px;}
.uktcn-form-bg{margin-bottom: 60px;}
.uktcn-form-bg .form-control, .uktcn-form-bg .form-control:focus{color: #ffffff;}

.uktcn-submit{
  background: #f4b33f;
    border: 1px solid #f4b33f;
    border-radius: 7px;
    padding: 10px 40px;
    width: 100%;
    position: relative;
    font-weight: 600;
}
.uktcn-submit span{
  width: 30px;
    height: 30px;
    border-radius: 6px;
    background: #2d2d2d;
    display: flex;
    /* margin-right: 30px; */
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 5px;
}
.uktcn-submit:hover{
  background-color: transparent;
  color: #f4b33f;

}
.about_last .owl-next {

  display: none !important;
}

.home_details img {

 width: 358px;
 height: 219px;
 border-radius: 20px;

}

/* custom css */

.bg-warning {
    background-color: #f4b33f!important;
}

div.ex1 {
  height: 300px;
  overflow-y: scroll;
}

.container-list .list-head{background: #f4b33f; padding: 15px 15px;}
.container-list .list-head.destination-header{margin-bottom: 20px;}
.list-head.destination-header .price2{flex:0 0 14%;}
.list-head.destination-header .origin{flex:0 0 52%;}
.list-head.destination-header .destination{flex:0 0 7.5%;}
.list-head.destination-header .destination h4{padding-left: 0px !important;}
.list-head.destination-header .ls{flex:0 0 24%;}
.list-head.destination-header .ls h4{padding-left: 0px !important;}
.container-list .list-head h4{text-transform: uppercase; color: #0f1011; font-size: 16px; font-weight: 600;}

.btn-warning{
  background-color: #f4b33f;
    border-color: #f4b33f;
}
.innerbanner .owl-item img{border-radius: 5px;}
.count-inlineflex button{display: flex;
    align-items: center;
    padding: 0px;
    justify-content: center;
}
.innerbanner .item .image{height: 243px; overflow: hidden; border-radius: 5px;
display: flex;
align-items: center;justify-content: center;
}
.product-count .qtyminus {
    line-height: 21px;
}
.checkout-btn{
  background: #ffc107 !important;
}


.checkout .edit-food{font-size: 14px; color: #ffc107; text-decoration: none; text-transform: uppercase; border-bottom-color: 1px solid #dddddd;}
.checkout .des-origin ul{list-style: none; position: relative;}
.checkout .des-origin ul:after{
    /*content: '';*/
    position: absolute;
    top: 19%;
    width: 1px;
    height: 48%;
    /* background: rgba(255,255,255,0.3); */
    left: 6.5%;
    border: 1px dashed rgba(255,255,255,0.3);
  }
.checkout .des-origin ul li{margin-bottom: 50px;}
.checkout .des-origin ul li:last-child{margin-bottom: 0px;}
.checkout .des-origin ul li span{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background: #000000;
    color: #ffc107;
    display: inline-block;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 1px solid rgba(255,255,255,0.3);
    float: left;
}
.checkout .des-origin ul li h5{
  color: #ffc107;
  font-size: 17px;
}
.checkout .des-origin ul li p{
  color: #ffffff;
  padding-left: 30px;
}

.checkout .refresh-contaner {
  background: rgba(255,255,255,0.1);
    border-radius: 5px;
    padding: 30px;
}
.checkout .delivery_address {
    border: 1px solid #ffc107;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;
    color: #fff;
    /* padding-bottom: 0px; */
    position: relative;
}
.checkout .delivery_address p b{
  font-weight: 500;
  margin-right: 8px;
}
.checkout .delivery_address div p{
  border-right: 1px solid rgba(255,255,255,0.3);
  margin-right: 15px;
  padding-right: 15px;
  
}
.delivery_address p{
  word-break: break-all;
}
.checkout .delivery_address div p:last-child{
  border-right: 0px;
}
.delivery_address.address_container > div {
  flex-wrap: wrap;
}
/* .login{
  color: #fff !important;
} */

/* Customer panel */
.order-list .order-first{display: flex; align-items: center; flex:0 0 12%; padding-left: 15px;}
.order-list .order-first .image{width: 100%; height: 100%; overflow: hidden; border-radius: 50%; position: relative;}
.order-list .order-first .round{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border:1px solid #ffffff;
  padding: 5px;
  overflow: hidden;
}
.order-list .order-first .image img{object-fit: cover; height: 100%;}
.order-list .order-first span{font-size: 16px; color: #ffffff; margin-right: 20px; display: inline-block;}
.order-list .title{flex: 0 0 70%; padding-left: 15px;}
.order-list .accordion-item{background-color: #181818 !important;}
.order-list .title h4{font-size: 20px; color: #ffffff; display: inline-block; margin-bottom: 0px;}
.order-list .title p.status{display: inline-block; padding: 5px 30px; color: #dfb978; background: rgba(223,185,120,0.1); margin-left: 20px; border-radius: 5px;}
.order-list .title p span{color: #a5a5a5; display: inline-block; margin-right: 15px; padding-right: 15px; border-right: 1px solid #a5a5a5;}
.order-list .title p span:last-child{border-right: 0px;}
.order-list .action{text-align: right; padding-right: 15px;}
.order-list .price{color: #ffffff; font-weight: 400; font-size: 25px;}
.order-list .action button::after{display: none;}
.order-list .action button{border-radius: 35px; color: #ffffff !important;}
.order-list .accordion-header{padding: 10px 0px; }
.order-list .accordion-header > div{flex-wrap: wrap;}
.order-list  .accordion-button.collapsed{
  border:1px solid #f4b33f; color: #000000 !important; 
  padding: 7px 20px; text-align: center; justify-content: center; background: #f4b33f !important; border-radius: 7px !important;}
.order-list .accordion-button{
  justify-content: center; 
  border:1px solid #f4b33f; 
  color: #f4b33f !important; 
  padding: 7px 20px; text-align: center; 
  border-radius: 7px !important;
  font-weight: 500;
}
.order-list .accordion-button:active, .order-list .accordion-button:focus{
  border:1px solid #f4b33f; 
}
.order-list .accordion-item{margin-bottom: 30px;}
.order-list .accordion-body{border-top:1px solid #6b6b6d; display: flex; justify-content: space-between; flex-wrap: wrap;}
.order-list .accordion-body .left{flex:0 0 50%;}
.order-list .accordion-body .right{flex:0 0 30%;}
.order-list .accordion-body ul{list-style: none; margin:0px; padding: 0px;}
.order-list .accordion-body p{font-size: 16px; color: #ffffff;}
.order-list .accordion-body span{color: #a5a5a5; margin-right: 15px;}
.order-list .reviews a i{
  color: #fcb116;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 5px;
}
.order-list .reviews li:last-child a i{
  margin-right: 0px;
}
.order-list .reviews a#get-reviews{
  background-color: #fcb116; font-size: 16px;
  color: #000000;
  border-radius: 35px;
  padding: 10px 20px;
  width: auto;
  display: inline-block;
  border:1px solid #fcb116;
}
.order-list .reviews a#get-reviews:hover{
  background-color: transparent;
  color: #fcb116;
}
.order-list .reviews p{display: inline-block; float: left;}
.order-list .reviews ul{float: left;}
.order-list .accordion-body .right li{display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;}
.order-list .accordion-body .right li:last-child{margin-bottom: 0px;}
.order-list .accordion-body .right li p{margin-bottom: 0px;}
.order-list .accordion-body .right li .total{color: #fcb116; font-size: 25px; font-weight: 400;}
.order-list .play-review{
  background-color: #462364 !important;
    border: 1px solid #462364 !important;
    font-size: 14px;
    color: #ffffff;
    margin-top: -8px;
    padding: 8px 30px;
}
.order-list .edit-review{margin-left: 20px; display: inline-block; text-transform: uppercase;}
.order-list .edit-review i{margin-right: 5px;}
.order-list .accordion-item{position: relative;}

/*rating */
.get-reviews .reviews-section{background: rgba(255,255,255,0.1); border:1px solid rgba(255,255,255,0.3); border-radius: 30px; padding: 50px 0px;}
.get-reviews .reviews-section .round{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    padding: 5px;
    overflow: hidden;
    margin:0px auto 30px;
}
.get-reviews .reviews-section .round .image{
  width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
.get-reviews .reviews-section .round .image img{height: 100%;}

.get-reviews .reviews-section h2{color: #ffffff; font-size: 20px;}
.get-reviews .reviews-section p span{color: #a5a5a5; display: inline-block; margin-right: 15px; padding-right: 15px; border-right: 1px solid #a5a5a5; font-size: 14px;}
.get-reviews .reviews-section p span:last-child{border-right: 0px;}
.get-reviews ul{list-style: none; justify-content: center; margin:0px; padding: 0px; margin-top: 40px;}
.get-reviews ul li a{font-size: 40px; margin-right: 10px; color: #ffffff; width: 34px; height: 37px; overflow: hidden; display: inline-block;}
.get-reviews ul li a img{width: 100%; transition: 0.5s;}
.get-reviews ul li a:hover img{position: relative;margin-top: -69px;}
.get-reviews .stars .rated img{position: relative;margin-top: -69px;}
.get-reviews .play-review a, .voice-record a, .voice-record button{ 
  /* background: #fbaf16; padding: 10px 30px; position: relative; margin-top: 30px; z-index: 0; */
  position: relative;bottom: 10px; width: 60px; height: 60px; background: #ffffff;  border-radius: 50%; margin:20px auto 0px; display: flex; align-items: center; justify-content: center;
}
.get-reviews .play-review{margin-top: 70px;}
.play-review .microphone{position: relative;}
.play-review .microphone#stopRecord{
  position: relative;
}
.play-review .microphone#stopRecord::before{
  content: ''; position: absolute; width: 100%; height: 100%; background: rgba(255,255,255,0.2); left: 0px; right: 0px; top:0px; border-radius: 35px; z-index: 1;}
.play-review .microphone#record:before{
  display: block;
}
.play-review .microphone#stopRecord:before{
  animation: round 3s infinite;
}
@keyframes round {
  0%{transform: scale(1)}
  100%{transform: scale(1.5); opacity: 0;}
}
.stop_icon{
  display: none;
}
.play-review .microphone#stopRecord .stop_icon{
  display: block;
}
.play-review .microphone#stopRecord .play_icon{
  display: none;
}
.delete_btn{
  border:0px !important;
  background: transparent;
  padding: 0px 15px;
}
.delete_btn i{
  font-size: 20px !important;
  color: #ff0000 !important;
  
  
}
.submit-review{margin-top: 30px; padding: 10px 30px !important;}
.play-system{background: #f1f3f4; border-radius: 35px; width: 63%;
  margin: 0 auto;
  overflow: hidden;
}
.play-review .icon img{width: 24px;}

/*.voice-record{display: none;}*/
.voice-record .icon{position: relative; width: 60px; height: 60px; background: #ffffff;  border-radius: 50%; margin:20px auto 0px; display: flex; align-items: center; justify-content: center;}
.voice-record .icon:before{content: ''; position: absolute; width: 100%; height: 100%; background: rgba(255,255,255,0.2); left: 0px; right: 0px; top:0px; border-radius: 35px; z-index: 1; animation: round 3s infinite;}
@keyframes round {
  0%{transform: scale(1)}
  100%{transform: scale(1.5); opacity: 0;}
}
.voice-record .icon img{width: 24px;}
#navbarNav li a{color: #ffffff; padding: 10px 12px;}
#navbarNav li a.active {color: #fcb214 !important;}


.searchresult .bg-darks{background-color: #181818;}
button{text-decoration: none !important;}
.searchresult.refresh-contaner .bg-dark{ position: relative;}
.login {text-align: center; justify-content: center; align-items: center; padding: 5px 30px; border-radius: 35px;}
.login i{margin-right: 10px;}
#modal-body .mb-3{position: relative;}
#modal-body .mb-3 .pass-show-hide{
  position: absolute; right: 0px;  
  background: transparent; 
  top: 34px; 
  border:0px; background-color: #fff; height: 52%;
  border-radius: 0px 5px 5px 0px !important;
}
#modal-body .mb-3 .pass-show-hide i{color: #000;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background: #fff0 !important;
}
input:-moz-autofill,
input:-moz-autofill:hover, 
input:-moz-autofill:focus, 
input:-moz-autofill:active{
  background: #fff0 !important;
}
.front-header .logo{padding-top: 30px; padding-bottom: 15px;}
.fa.fa-eye-slash{font-size: 18px; color: #898989 !important;}
.fa.fa-eye{font-size: 18px; color: #000000 !important;}
.play-audio{
  color: #fff;
  background-color: #462364 !important;
    border: 1px solid #462364 !important;
    font-size: 14px;
    padding: 11px .75rem;
    border:0px;
    border-radius: 35px;
}
.fixedbar{
  position: fixed;
  z-index: 999;
  background: #fff;
  bottom: 0px;
  left: 0px;
  /* height: 30px; */
  width: 100%;
  padding: 5px 15px 5px 0px;
  box-sizing: border-box;
}
.fixedbar h3{font-size: 16px; 
background-color: #ffc107; color: #000000; display: inline-block; margin-bottom: 0px;
font-weight: 400;

padding: 5px 20px; position: relative;}
.fixedbar h3:after{
  content: '';
    position: absolute;
    border: 10px solid #ffc107;
    border-left-color: transparent;
    border-bottom-color: transparent;
    width: 15px;
    transform: rotate( 
45deg);
    right: -10px;
}

.fixedbar p{color: #000000; margin-bottom: 0px; margin-left: 30px;}
.fixedbar p span{color: #462364 ; font-weight: 600;}
.fixedbar div{
  flex:0 0 80%;
}
.fixedbar a{
  color: #fff;
  background-color: #462364 ;
  border: 1px solid #462364 ;
  font-size: 14px;
  padding: 11px 30px;
  border: 0px;
  border-radius: 35px;
  animation: scale 2s infinite forwards ease-in-out;
  border:1px solid #462364;
}
.fixedbar a:hover{
  background-color: transparent;
  color: #462364;
}
@keyframes scale {
  0%{transform: scale(1);}
  50%{transform: scale(1.05);}
  100%{transform: scale(1);}
}


/*cart page*/
.cart h3{color: #ffffff; font-size: 20px; font-weight: 400; margin-bottom: 0px;}
.continue-shopping{
  border-radius: 35px;
  background-color: #0ca41b;
  color: #fff;
  padding: 5px 10px;
  border:0px;
  font-size: 12px;
}
.payment-summery .heading{
  margin-bottom: 20px;
}
.order-list .items{
  background-color: #181818;
  padding-top: 20px;
}
.order-list .items{padding: 15px; margin-bottom: 10px; border-bottom: 1px solid rgba(255,255,255,0.2); flex-wrap: wrap; border-radius: 10px;}
.order-list .items:last-child{border-bottom: 0px;}
.order-list .items .image-title{ flex:0 0 30%; }
.order-list .items .price{flex:0 0 15%; display: flex;
    align-items: flex-start;
    justify-content: center;}
.order-list .items .qty{flex:0 0 15%; display: flex;
    align-items: flex-start;
    justify-content: center;}
.order-list .items span{display: none;}
.order-list .items .total-price{flex:0 0 20%;text-align: center;}
.order-list .items .del-button{flex:0 0 20%; text-align: center;}
.order-list .items .image-title h3{color: #ffffff; font-size: 16px; margin-bottom: 10px;}
.order-list .items .image-title p{color: #fcb617; margin-bottom:0px;}
.order-list .items .image-title .image{margin-right: 15px; width: 56px; height: 50px;}
.order-list .items .image-title .image img{max-width: 100%; object-fit: cover; height: 100%; overflow: hidden; border-radius: 6px;}
.order-list .items .price p{color: #ffffff; text-align: center;}
.order-list .items .qty{display: flex; align-items: flex-start; justify-content: center;}
.order-list .items .qty input{width: 40px; border:0px; border-radius: 5px; text-align: center; background: #fff; color: #000000; font-weight: 600; padding-left:0px;}
.order-list .items .qty p{margin-bottom: 0px; color: #cecece; margin-right: 15px;}
.order-list .items .total-price p{color: #fcb617; font-size: 20px; text-align: center;}
.patment-details{
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
}
.patment-details .payment-id{border-radius: 10px; text-align: center; border:1px solid #f1f1f1; padding: 10px 0px; margin-bottom: 15px; background: #f1f1f1; font-weight: 600;}
.patment-details ul{
  list-style: none;
  margin-left:0px;
  padding-left: 0px;
  margin-top: 30px;
}
.patment-details ul li{display: flex; align-items: center; justify-content: space-between; width: 100%; margin-bottom: 20px;}
.patment-details ul li span{font-weight: 500; font-size: 14px; color: #9b9b9b;}
.patment-details .total{color: #fcb617; }
.patment-details .payment{background: #fcb617; width: 100%; }
.patment-details ul li p{margin-bottom: 0px;}
.order-list-header{padding: 15px 15px;}
.order-list-header div:first-child{flex: 0 0 30%;}
.order-list-header div:nth-of-type(2n){flex: 0 0 15%; text-align: center;}
.order-list-header div:nth-of-type(3n){flex: 0 0 15%; text-align: center;}
.order-list-header div:nth-of-type(4n){flex: 0 0 20%; text-align: center;}
.order-list-header div:nth-of-type(5n){flex: 0 0 20%; text-align: center;}
.order-list-header div p{ color: #fff; margin-bottom:0px; }


.badge-light {
    color: #fff;
    background-color: #B90504;
    margin-top: -2px;
    float: right;
    /* margin-left: -18px; */
    border-radius: 50% !important;
    width: 18px;
    height: 15px;
    padding: .25em .4em !important;
    font-size: 70% !important;
    position: absolute;
}
/* .badge {
  display: flex;
  
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50px;
} */

.name {
  background: url(/images/icon_user.png) no-repeat;
  background-position: 97% center;
}




.email {
background: url(/images/email.png) no-repeat;
background-position: 97.2% center;
position: relative;
}
input.email:autofill{
background: #000000 url(/images/email.png) no-repeat !important;
background-position: 97.2% center !important;
}
.email:after{
  content:'';
  position: absolute;
  right:0px;
  top:5px;
  background: #fff0 url(/images/email.png) no-repeat !important;
  background-size: 19px 14px;
  width: 19px;
  height: 14px;
}
.innersearch{
  padding-bottom: 100px;
}
.add_sec .price3{min-width: 120px; text-align: right; font-size: 24px; margin-bottom: 0px;}
.order-list .accordion-body p.p-decsription{
  color: #a5a5a5;
  word-break: break-all;
}
.order-list .accordion-body  p.p-decsription span{
  font-weight: 500;
  color: #fcb116;
}
.revivew-given{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  /* border-bottom:1px solid rgba(255,255,255,0.2) */
}
.revivew-given p{
  margin-bottom: 0px;
}
.revivew-given .get-reviews{
  display: inline-block; width: 100%;
}
#accordionExample .accordion-header{
  padding-right: 10px;
  padding-left: 15px;
}
#registeruser input:focus, #login input:focus{
  background: #000000;
  color: #fff;
}
#paypalPay{width: 100%;}
#search_form input{border:0px; width: 100%; background: #fff; color: #000; height: 35px; padding-left: 21px;}
#search_form input:-moz-focus{outline: none !important; background: transparent !important; }
#search_form input:-webkit-focus{outline: none !important; background: transparent !important; }
#search_form input:-moz-autofill{background: transparent !important;}
#search_form input:-webkit-autofill{background: transparent !important;}

.border-secondarys {
	border:1px solid #3d3d3d !important;
}
input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.list .image{
  max-height: 200px;
  overflow: hidden;
  border-radius: 20px;
}
.list .image img{
  height: 200px;
  border-radius: 0px;

}

header .cart{
  position: relative;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-right: 20px;
}
.cart{
  padding-top: 60px;
  padding-bottom: 100px;
}
.review{
  padding-bottom: 80px;
}
.food_description{
  text-align: left;
}
.food_description span{
  color: #ffab00;
}
.navbar-toggler:focus{box-shadow: 0 0 0 0rem !important;}
#modal-body input[type="email"]:focus{background-color: #000000; color: #fff;}

.owl-carousel-main .owl-stage-outer{
  overflow: visible !important;
}
.owl-carousel-center .owl-stage-outer{
  overflow: visible !important;
}
.inputCheck{
  padding-left: 0px !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.inputCheck input[type="checkbox"]{
  width: 17px;
    height: 17px;
    border-radius: 12px;
    padding: 6px;
    margin-right: 10px;
}
.inputCheck input[type="checkbox"]:checked{
  background-color: #462364;
}
.ddds{display: none;}
#accordionExample .accordion-header{
  padding-right: 0px;
  padding-left: 0px;
}
.auth-name{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 70px;
  display: inline-block;
  padding: 0px 0px;
  position: relative;
  top:4px;
}
.accordion-button:not(.collapsed)::after{
  transform: rotate(180deg) !important;
  background-color: #f4b33f !important;
  color: #000;
}
.free-shepping{
  width: 100%;
  display: flex;
  float: left;
  align-items: center;
  margin-bottom: 30px;
}
.free-shepping p{margin-bottom: 0px;}
.free-shepping img{
  width: 50px;
    margin-right: 10px;
    padding: 8px;
    /* background: #fcb116; */
    border-radius: 4px;
    border:1px dashed #6a6a6a;
}
.free-shepping span{
  color: #fcb116;
  font-weight: 500;
}

.dark-mode .table-hover tbody tr:hover, .table-hover tbody tr:hover{
  color: #ffffff !important;
}
.swal2-title{
  font-size: 20px !important;
  color: #000;
}
.btn-text button{
  float: right;

}
.btn-text p{
  text-align: right;
  width: 100%;
  display: inline-block;
}
.destination{flex: 0 0 63%;}
.status{flex:0 0 31%;}
.list-head.destination-header .price2{padding-left: 5px;}
.list{
  padding-bottom: 100px;
}

.boarding .no_sec h3{
  /* animation: blinker 2s linear infinite; */
  transform: scale(1);
}
#cart_count i{font-size: 25px;}

#cart_count span{
  width: 25px;
    height: 25px;
    background-color: #ffff00;
    border-radius: 35px;
    position: absolute;
    top: -10px;
    color: #2d2d2d !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    right: -12px;
    font-size: 12px;
    border:2px solid #2d2d2d;
    
}
.mobile_footer #cart_counts p{
  display: inline-block;
margin-left: 5px;
font-weight: 600;
margin-bottom: 0px;
color: #f4b33f;
position: relative;
}
.dropdown-menu.show{    background: #f4b33f;}
/* @keyframes blinker {

  50% {
    opacity: 0.3;
    transform: scale(0.9);
  }
} */

.food_brand.food_brand02{
  width: 220px;
}
.navbar-toggler.collapsed{
  position: relative;
z-index: 99999;
}
#password:focus, .email:focus{outline: 1px solid #dddddd !important; 
  color: #ffffff !important;
  overflow: hidden;
}
.navbar-toggler{position: relative; z-index: 999;}

.offer-list{
  padding-bottom: 70px;
}
.card.bg-warning .bootstrap-datetimepicker-widget .table td,
  .card.bg-warning .bootstrap-datetimepicker-widget .table th,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget .table td,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget .table th {
    border: none;
  }
  
  .card.bg-warning .bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
  .card.bg-warning .bootstrap-datetimepicker-widget table td.day:hover,
  .card.bg-warning .bootstrap-datetimepicker-widget table td.hour:hover,
  .card.bg-warning .bootstrap-datetimepicker-widget table td.minute:hover,
  .card.bg-warning .bootstrap-datetimepicker-widget table td.second:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.day:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.hour:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.minute:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.second:hover {
    background-color: #dda600;
    color: #1f2d3d;
  }
  
  .card.bg-warning .bootstrap-datetimepicker-widget table td.today::before,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: #1f2d3d;
  }
  
  .card.bg-warning .bootstrap-datetimepicker-widget table td.active,
  .card.bg-warning .bootstrap-datetimepicker-widget table td.active:hover,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.active,
  .card.bg-gradient-warning .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #ffce3a;
    color: #1f2d3d;
  }

  .card.bg-lightblue .bootstrap-datetimepicker-widget .table td,
  .card.bg-lightblue .bootstrap-datetimepicker-widget .table th,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget .table td,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget .table th {
    border: none;
  }
  
  .card.bg-lightblue .bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.day:hover,
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.hour:hover,
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.minute:hover,
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.second:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.day:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.hour:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.minute:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.second:hover {
    background-color: #32769d;
    color: #fff;
  }
  
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.today::before,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.today::before {
    border-bottom-color: #fff;
  }
  
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.active,
  .card.bg-lightblue .bootstrap-datetimepicker-widget table td.active:hover,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.active,
  .card.bg-gradient-lightblue .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #5fa4cc;
    color: #fff;
  }

  .datetimepicker {
    padding: 4px;
    margin-top: 1px;
    border-radius: 4px;
    direction: ltr;
  }
  
  .datetimepicker-inline {
    width: 220px;
  }
  
  .datetimepicker.datetimepicker-rtl {
    direction: rtl;
  }
  
  .datetimepicker.datetimepicker-rtl table tr td span {
    float: right;
  }
  
  .datetimepicker-dropdown, .datetimepicker-dropdown-left {
    top: 0;
    left: 0;
  }
  
  [class*=" datetimepicker-dropdown"]:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #cccccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  
  [class*=" datetimepicker-dropdown"]:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
  }
  
  [class*=" datetimepicker-dropdown-top"]:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #cccccc;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-bottom: 0;
  }
  
  [class*=" datetimepicker-dropdown-top"]:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #ffffff;
    border-bottom: 0;
  }
  
  .datetimepicker-dropdown-bottom-left:before {
    top: -7px;
    right: 6px;
  }
  
  .datetimepicker-dropdown-bottom-left:after {
    top: -6px;
    right: 7px;
  }
  
  .datetimepicker-dropdown-bottom-right:before {
    top: -7px;
    left: 6px;
  }
  
  .datetimepicker-dropdown-bottom-right:after {
    top: -6px;
    left: 7px;
  }
  
  .datetimepicker-dropdown-top-left:before {
    bottom: -7px;
    right: 6px;
  }
  
  .datetimepicker-dropdown-top-left:after {
    bottom: -6px;
    right: 7px;
  }
  
  .datetimepicker-dropdown-top-right:before {
    bottom: -7px;
    left: 6px;
  }
  
  .datetimepicker-dropdown-top-right:after {
    bottom: -6px;
    left: 7px;
  }
  
  .datetimepicker > div {
    display: none;
  }
  
  .datetimepicker.minutes div.datetimepicker-minutes {
    display: block;
  }
  
  .datetimepicker.hours div.datetimepicker-hours {
    display: block;
  }
  
  .datetimepicker.days div.datetimepicker-days {
    display: block;
  }
  
  .datetimepicker.months div.datetimepicker-months {
    display: block;
  }
  
  .datetimepicker.years div.datetimepicker-years {
    display: block;
  }
  
  .datetimepicker table {
    margin: 0;
  }
  
  .datetimepicker  td,
  .datetimepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
  }
  
  .table-striped .datetimepicker table tr td,
  .table-striped .datetimepicker table tr th {
    background-color: transparent;
  }
  
  .datetimepicker table tr td.minute:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  
  .datetimepicker table tr td.hour:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  
  .datetimepicker table tr td.day:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  
  .datetimepicker table tr td.old,
  .datetimepicker table tr td.new {
    color: #999999;
  }
  
  .datetimepicker table tr td.disabled,
  .datetimepicker table tr td.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
  }
  
  .datetimepicker table tr td.today,
  .datetimepicker table tr td.today:hover,
  .datetimepicker table tr td.today.disabled,
  .datetimepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }
  
  .datetimepicker table tr td.today:hover,
  .datetimepicker table tr td.today:hover:hover,
  .datetimepicker table tr td.today.disabled:hover,
  .datetimepicker table tr td.today.disabled:hover:hover,
  .datetimepicker table tr td.today:active,
  .datetimepicker table tr td.today:hover:active,
  .datetimepicker table tr td.today.disabled:active,
  .datetimepicker table tr td.today.disabled:hover:active,
  .datetimepicker table tr td.today.active,
  .datetimepicker table tr td.today:hover.active,
  .datetimepicker table tr td.today.disabled.active,
  .datetimepicker table tr td.today.disabled:hover.active,
  .datetimepicker table tr td.today.disabled,
  .datetimepicker table tr td.today:hover.disabled,
  .datetimepicker table tr td.today.disabled.disabled,
  .datetimepicker table tr td.today.disabled:hover.disabled,
  .datetimepicker table tr td.today[disabled],
  .datetimepicker table tr td.today:hover[disabled],
  .datetimepicker table tr td.today.disabled[disabled],
  .datetimepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
  }
  
  .datetimepicker table tr td.today:active,
  .datetimepicker table tr td.today:hover:active,
  .datetimepicker table tr td.today.disabled:active,
  .datetimepicker table tr td.today.disabled:hover:active,
  .datetimepicker table tr td.today.active,
  .datetimepicker table tr td.today:hover.active,
  .datetimepicker table tr td.today.disabled.active,
  .datetimepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069;
  }
  
  .datetimepicker table tr td.active,
  .datetimepicker table tr td.active:hover,
  .datetimepicker table tr td.active.disabled,
  .datetimepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  
  .datetimepicker table tr td.active:hover,
  .datetimepicker table tr td.active:hover:hover,
  .datetimepicker table tr td.active.disabled:hover,
  .datetimepicker table tr td.active.disabled:hover:hover,
  .datetimepicker table tr td.active:active,
  .datetimepicker table tr td.active:hover:active,
  .datetimepicker table tr td.active.disabled:active,
  .datetimepicker table tr td.active.disabled:hover:active,
  .datetimepicker table tr td.active.active,
  .datetimepicker table tr td.active:hover.active,
  .datetimepicker table tr td.active.disabled.active,
  .datetimepicker table tr td.active.disabled:hover.active,
  .datetimepicker table tr td.active.disabled,
  .datetimepicker table tr td.active:hover.disabled,
  .datetimepicker table tr td.active.disabled.disabled,
  .datetimepicker table tr td.active.disabled:hover.disabled,
  .datetimepicker table tr td.active[disabled],
  .datetimepicker table tr td.active:hover[disabled],
  .datetimepicker table tr td.active.disabled[disabled],
  .datetimepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  
  .datetimepicker table tr td.active:active,
  .datetimepicker table tr td.active:hover:active,
  .datetimepicker table tr td.active.disabled:active,
  .datetimepicker table tr td.active.disabled:hover:active,
  .datetimepicker table tr td.active.active,
  .datetimepicker table tr td.active:hover.active,
  .datetimepicker table tr td.active.disabled.active,
  .datetimepicker table tr td.active.disabled:hover.active {
    background-color: #003399;
  }
  
  .datetimepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .datetimepicker .datetimepicker-hours span {
    height: 26px;
    line-height: 26px;
  }
  
  .datetimepicker .datetimepicker-hours table tr td span.hour_am,
  .datetimepicker .datetimepicker-hours table tr td span.hour_pm {
    width: 14.6%;
  }
  
  .datetimepicker .datetimepicker-hours fieldset legend,
  .datetimepicker .datetimepicker-minutes fieldset legend {
    margin-bottom: inherit;
    line-height: 30px;
  }
  
  .datetimepicker .datetimepicker-minutes span {
    height: 26px;
    line-height: 26px;
  }
  
  .datetimepicker table tr td span:hover {
    background: #eeeeee;
  }
  
  .datetimepicker table tr td span.disabled,
  .datetimepicker table tr td span.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
  }
  
  .datetimepicker table tr td span.active,
  .datetimepicker table tr td span.active:hover,
  .datetimepicker table tr td span.active.disabled,
  .datetimepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  
  .datetimepicker table tr td span.active:hover,
  .datetimepicker table tr td span.active:hover:hover,
  .datetimepicker table tr td span.active.disabled:hover,
  .datetimepicker table tr td span.active.disabled:hover:hover,
  .datetimepicker table tr td span.active:active,
  .datetimepicker table tr td span.active:hover:active,
  .datetimepicker table tr td span.active.disabled:active,
  .datetimepicker table tr td span.active.disabled:hover:active,
  .datetimepicker table tr td span.active.active,
  .datetimepicker table tr td span.active:hover.active,
  .datetimepicker table tr td span.active.disabled.active,
  .datetimepicker table tr td span.active.disabled:hover.active,
  .datetimepicker table tr td span.active.disabled,
  .datetimepicker table tr td span.active:hover.disabled,
  .datetimepicker table tr td span.active.disabled.disabled,
  .datetimepicker table tr td span.active.disabled:hover.disabled,
  .datetimepicker table tr td span.active[disabled],
  .datetimepicker table tr td span.active:hover[disabled],
  .datetimepicker table tr td span.active.disabled[disabled],
  .datetimepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  
  .datetimepicker table tr td span.active:active,
  .datetimepicker table tr td span.active:hover:active,
  .datetimepicker table tr td span.active.disabled:active,
  .datetimepicker table tr td span.active.disabled:hover:active,
  .datetimepicker table tr td span.active.active,
  .datetimepicker table tr td span.active:hover.active,
  .datetimepicker table tr td span.active.disabled.active,
  .datetimepicker table tr td span.active.disabled:hover.active {
    background-color: #003399;
  }
  
  .datetimepicker table tr td span.old {
    color: #999999;
  }
  
  .datetimepicker th.switch {
    width: 145px;
  }
  
  .datetimepicker th span.glyphicon {
    pointer-events: none;
  }
  
  .datetimepicker thead tr:first-child th,
  .datetimepicker tfoot th {
    cursor: pointer;
  }
  
  .datetimepicker thead tr:first-child th:hover,
  .datetimepicker tfoot th:hover {
    background: #eeeeee;
  }

  .checkdestination {
    pointer-events: none;
    cursor: default !important;
    opacity: 0.6;
  }
  
.rest-password input{color: #000000;}
.rest-password input:focus{
  color: #000 !important;
}
.rest-password #password:focus{
  color: #000 !important;
}
.rest-password{
  margin-bottom: 70px;
}
.home_details{
  width: 83%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0px 15px;
}
.login-social{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 15px;
  margin-bottom: 20px;
}
.login-social li a{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 7px;
  border-radius: 35px;
  box-shadow: 5px 5px 15px #e1e1e1;
}
.login-social li:last-child a{
  margin-right: 0px;
}
.login-social li a i{
  font-size: 20px;
  color: #fff;
}
.login-social li a.fb i{
    color: #3b5998;
  
}
.login-social li a.li{
  color: #007ab9;
}
.login-social li a.gp i{
  color: #f44336;
}
.or{
  display: inline-block;
    font-size: 14px;
    color: #b9b9b9;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
}
.regis p{
  font-size: 16px;
}
.regis a{
  color: #ffab00 !important;
  
}
.nofification{
  padding: 0px 0px;
  margin-bottom: 12%;
}
.dropdown-menu{
  width: 100%;
  min-width: 500px;
}
.notification-list{
  padding: 0px 0px;
}
.notification-list ul{ 
  list-style: none;
  background-color: #181818;
  border-radius: 5px;
  padding: 0px;
  overflow: hidden;
  border: 1px solid #383838;
}
.notification-list ul li{
  border-bottom: 1px solid #383838;
    padding: 15px 20px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
}
.notification-list ul li:last-child{
  border-bottom: 0px;
}
.notification-list ul li span{
  width: 10px;
  height: 10px;
  background: #462364;
  border-radius: 50px;
  display: inline-block;
  margin-right: 15px;
  margin-top: 5px;
}
.notification-list ul p{ color: #b1b0b0; font-size: 14px;}
.notification-list ul h4{ color: #ffffff; font-size: 16px;}
.notification-list ul .notify-date{}
.notification-list ul .notify-date p{ margin-bottom: 0px;}
.notification-list ul .notify-date font{  border-radius: 50px; padding: 5px 20px; margin-right: 10px; font-weight: 500;}
.notification-list ul .notify-date p{color: #b1b0b0;}
.pending{background-color: rgba(244, 179, 63, 0.2); color: #f4b33f}
.success{background-color: rgba(76, 175, 80, 0.2); color: #4caf50;}
.going{background-color: rgba(70, 35, 101, 0.2); color: #462364;}
.cancel{background-color: rgba(186, 34, 41, 0.2); color: #ba2229;}
.notification-list ul li.active{
  background-color: #181818;
}
.notification-list ul li.active h4{
  color: #462364;
}
.year{
  margin:20px 0px;
  width: 100%;
}
.year span{
  border-radius: 35px;
  background-color: #ffbc07;
  display: inline-block;
  padding: 7px 20px;
  
}
.nofification .pagination{ justify-content: center; margin-top: 25px;}

.nofification .pagination a, .nofification .pagination span{border: 0px;
  border-radius: 35px;
  background: transparent;
  /* border: 1px solid #fff; */
  width: 36px;
  height: 29px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nofification .pagination .active.page-item span{
  color: #000000; background: transparent; font-weight: 600;
  color: #ffbc07;
  border:1px solid #ffbc07;
  border-radius: 5px;
}

/* .nofification .pagination .active.page-item a{margin-right: 0px;} */

.nofification .pagination .page-item:first-child a{ background-color: transparent; color: #f4b33f; width: inherit; height: 100%; font-size: 37px;  line-height: 10px;}
.nofification .pagination .page-item:last-child a{ background-color: transparent; color: #f4b33f; width: inherit; height: 100%; font-size: 37px;
  line-height: 10px;}
.nofification .page-item.active .page-link{
  background-color: #2e2e2e;
    border-color: #2e2e2e;
  }
  .nofification .page-item.disabled .page-link{
    background: transparent;
    font-size: 37px;  line-height: 10px;
  }



  .status_color{
    margin-left: 10px;
  }
  .status_color ul{list-style: none; display: flex; align-items: center; margin: 0px; padding-left: 0px;}
  .status_color ul li{
    border-radius: 3px;
    width: 23px;
    height: 43px;
    margin-right: 3px;
    background-size: cover;
    background-position: center;
  }
  .status_color ul li:last-child{margin-right: 0px;}
  .first_sec.two-row-two{
    margin-left: 10px;
  }
  .boarding .status_color ul li{
    background: url(/images/bg2.svg) no-repeat;
    background-size: cover;
  }
  .delayed .status_color ul li{
    background: url(/images/bg3.svg) no-repeat;
    background-size: cover;
  }
  .make_offer .status_color ul li{
    background: url(/images/bg4.svg) no-repeat;
    background-size: cover;
  }
  .last_minutes .status_color ul li{
    background: url(/images/bg5.svg) no-repeat;
    background-size: cover;
  } 
  .last_minutes .first_sec, .last_minutes .status_color{
      animation: blinker 1.5s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .destination-table .first_sec{margin-left: 10px;}
  .destination-table .first_sec.one_sec{margin-left: 0px;}
  .destination-table .list-sec{margin-bottom: 15px;}


.user-login{display: flex; align-items: center; justify-content: flex-end;}
.user-login .header_login{ margin-left: 10px; flex:0 0 50%; position: relative;}
.user-login .header_login font{
	text-overflow: ellipsis;
	white-space: nowrap;
	flex:0 0 67%;
	overflow: hidden;
}
.user-login .header_login .btn_style{
	justify-content: flex-end;
	width: 100%;
}

.food_details_image.mobile_view{flex:0 0 40%; text-align: center;}
.welcome-table.container-list .list-head{margin-bottom: 15px;}
.alert-msg .alert{margin-bottom: 0px;}
#forget-email:focus{color: #000 !important;}
.bi-list::before, .bi-x::before{display: none !important;}

.offer_image{width: 104px; margin-right: 15px; height: 104px; flex:0 0 104px;}
.offer_image img{border-radius: 50%; border:1px solid #f4b33f; width: 100%; height: 100%;}


/* how it works */
.how-it-works{
  color: #fff;
}

.how-it-works .container{
  /* padding-top: 5%; */
  /* padding-bottom: 5%; */
}
.how-it-works .row2{
  padding-bottom: 50px;
}
.how-it-works .row3{
  padding-bottom: 30px;
}
.hiw-row-heading{
  margin: 3rem auto;
}
.hiw-row-heading span{
  background-color: rgb(255, 198, 11);
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: #000 !important;
  margin-right: 1rem;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
}

.hiw-row-heading h3{
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.5em;
  margin-bottom: 0;
}

.hiw-text{
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(77, 75, 75);
  position: relative;
  z-index: 9;
}

.hiw-text p{
  font-size: 1.2em;
  line-height: 1.7em;
}

.hiw-text ul{
  padding-left: 0;
}

.hiw-text ul li{
  list-style: none;
  font-size: 1em;
  line-height: 1.7em;
  margin-bottom: 1rem;
}
.hiw-text ul li span{
  color: rgb(255, 198, 11);
  margin-right: 0.5rem;
}

.img-container{
  object-fit: cover;
  display: flex;
  justify-content: center;
   
}
.img-container .image {
  width: 60%;
}

.row1 .col-lg-7,
.row2 .col-lg-7,
.row3 .col-lg-7,
.row4 .col-lg-7,
.row5 .col-lg-7{
  position: relative;
}
.row1 .col-lg-7::after{
  content: '';
  position: absolute;
  left: 25%;    
  margin: 0 auto;
  background: url("/images/line12.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.row2 .col-lg-7::after{
  content: '';
  position: absolute;
  left: -30%;    
  margin: 0 auto;
  background: url("/images/line13.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.row3 .col-lg-7::after{
  content: '';
  position: absolute;
  left: 25%;    
  margin: 0 auto;
  background: url("/images/line14.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.row4 .col-lg-7::after{
  content: '';
  position: absolute;
  left: -20%;
  top: 80%;    
  margin: 0 auto;
  background: url("/images/line15.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}
.row5 .col-lg-7::after{
  content: '';
  position: absolute;
  left: 35%;    
  margin: 0 auto;
  background: url("/images/line16.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.row4{
  margin-top: 3%;
  margin-bottom: 9%;
}
.row5{
  margin: 9% 0;
}
.row6{
  margin-top:9%;
}
/* #slider{
  width: 100%;
} */
.invalid-feedback{
  display: block !important;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden !important;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input[autocomplete="off"]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

/* @media (max-width:1400px) { 


  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1318px;
}



  } */
  .destination-table .status img{display: none;}
  @media only screen and (max-width:1920px) {
    .container-list .list-head{
      width: 100%;
      margin: 0 auto;
    }
    .destination-table .no_sec{
      width: 25px;
      height: 48px;
    }
    .welcome-table .no_sec{
      width: 25px;
      height: 48px;
    }
  }
  @media only screen and (max-width:1399px) {
    .container-list .list-head{
      width: 84%;
    }
    .home_details{
      width: 100%;
    }
    .destination-table .no_sec, .welcome-table .no_sec {
      width: 21px;
      height: 40px;
    }
    
  }
  @media only screen and (max-width:1399px) {
    .container-list .list-head{
      width: 100%;
    }
  }
  @media only screen and (max-width:1280px) {
    #navbarNav li a{
      padding: 10px 7px;
      font-size: 14px;
    }
    .two-row-one{
      /* flex:0 0 74%; */
      overflow: hidden;
      height: 33px;
    }
    .two-row-two{
      height: 33px;
      overflow: hidden;
    }
    .welcome-table .two-row-one, .welcome-table .first_sec.two-row-two{height: 41px;}
    .welcome-table .two-row-one{flex: 0 0 61%;}


    
    
    /* .destination-table .boarding .status_color ul li, .destination-table .delayed .status_color ul li, .destination-table .status_color ul li{} */
    .destination-table .status_color ul li{width: 21px;}
    .destination-table .no_sec{width: 21px; background-size: 100% auto; background-position: center;} .make_offer .status_color ul li, .destination-table .last_minutes .status_color ul li{
      background-size: 100% auto; background-position: center;
    }

  }
  @media only screen and (max-width:1199px) {
    .destination-table .status_color ul li{width: 17px;}
    .destination-table .no_sec{width: 17px; background-size: 100% auto; background-position: center;}
  }
  @media (max-width:1024px) {
    .fs-5 {
      font-size: 15px !important;
    }
    .top_sec1 .btn-outline-primary {
      font-size: 13px;
    }
    .owl-nav .owl-prev {
      left: -23px !important;
      top: 77px !important;
    }
    .owl-nav .owl-next {
      right: -23px !important;
      top: 77px !important;
    }
    .navbar-light .navbar-nav .nav-link {
      color: #fff;
      font-size: 17px;
    }
    .btn-primary {
      width: 136px;
      padding: 9px 0 !important;
    }
    .social-icons-ql li {
      /* margin: 10px 6px !important; */
    }
    #navbarNav li a{padding: 10px 9px;}
    /* .uktcn-franchise-dv{height: 233px;} */
    .two-row-one, .two-row-two{
      height: 22px;
    }
    .no_sec{width: 18px;}
    .destination-table .status_color ul li{width: 17px; background-size: 100% auto;}
    
  }
  @media only screen and (max-width:991px){
    .navbar-toggler{display: block; padding: 0px !important;}
    .notification h3{display: none;}
    #dropdownMenuButton1{padding: 10px 0px !important; }
    .order-list .action{flex:0 0 100%;}
    .order-list .accordion-item{padding: 15px;}
    .order-list .price{position: relative; left:0px;}
    .order-list .accordion-body{border-top: 0px;}

    .custom-navigation{position: absolute; width: 100%; }
    .navbar-collapse {
      background: #000;
      padding: 20px;
      padding-top: 0px;
      text-align: center;
      margin-top: 10px;
      border: 1px solid;
      position: fixed;
      /* margin: 29px; */
      top: 84px;
      right: 0;
      width: 90%;
      z-index: 9;
      left: 0;
      margin: 0 auto;
    }
    .nav-link {
      color: rgba(0, 0, 0, .9);
      border-bottom: 1px dotted #ccc;
    }
    /* .uktcn-franchise-dv{height: 173px;} */

    
  
.owl-nav .owl-prev, .owl-nav .owl-next{top: 97px !important;}
#navbarNav{
  /* height: 100vh; */
  padding: 0px;
  width: 100%;
  z-index: 9999;
  background: rgba(0,0,0,0.9);
}
#navbarNav .navbar-nav{
  background-color: #000;
  position: absolute;
  width: 100%;
  z-index: 999999;
}
.ddds{
  position: absolute;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: -83px;
  left: 0px;
  z-index: 0;
  display: block;
}
.food_brand{
  font-size: 16px;
  width: 183px;
  height: 42px;
}

.welcome-table .no_sec, .welcome-table .first_sec, .welcome-table .first_sec.two-row-two, .status_color ul li{height: 35px;}
.no_sec h3 {
  font-size: 14px;
}
.welcome-table .no_sec{background-size: 100% 100%;}
.status_color ul li{width: 18px;}
.welcome-table .two-row-one{flex: 0 0 61%;}

.destination-table .first_sec{height: 42px; overflow: hidden}
.destination-table .no_sec{height: 100%; background-size: 100% auto;
  background-position: center;}

  .destination-table .status_color ul li{width: 19px; background-position: center;}
  .destination-table .two_sec{flex:0 0 30%;}
  .list-head.destination-header .price2{flex: 0 0 19%;}
  .list-head.destination-header .origin{flex: 0 0 33%;}
  .list-head.destination-header .destination{flex: 0 0 10.5%;}


}
  @media (max-width:800px) {
    .home_price {
      float: left;
      margin-right: 20px;
      margin-top: -4px;
    }
    .home_details img {
      width: 100%;
      margin-bottom: 20px;
      height: auto;
    }
    .sec-101 {
      position: absolute;
      top: 104px;
      left: 7px;
      right: 6px;
    }
    .price3 {
      position: absolute;
      top: 26px;
      right: 96px;
      width: auto;
    }
    .add_sec {
      position: relative;
    }
    /* .r_right {
  
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
    } */
    .product-count {
      border-radius: 0 !important;
      margin-top: 20px;
    }
    .food_brand {
      display: none;
    }
    .social-icons-ql {
      display: flex;
    }
    header .btn-primary {
      width: 114px;
      padding: 9px 0 !important;
    }
    .container-list {
      width: 100%;
      padding: 0 5px;
    }
    .navbar-light .navbar-toggler i {
      font-size: 36px;
    }
    .ingradients {
      height: auto;
    }
    .logo {
      width: 100%;
      margin-top: -66px;
      position: relative;
      margin-bottom: 11px;
    }
    .add_sec {
      text-align: center;
    }
    .clint_img img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
    .owl-nav .owl-next {
      right: -9px !important;
      top: 120px !important;
    }
    .owl-nav .owl-prev {
      left: -9px !important;
      top: 114px !important;
    }
    .list {
      padding-bottom: 42px;
    }
    
    .no_sec {
      width: 16px;
      height: 23px;
    }
    .w-75 {
      width: 100%!important;
    }
    .logo {
      width: 71%;
    }
    
    .about p {
      text-align: center;
    }
    .work img {
      margin-bottom: 0 !important;
    }
    .work .col-md-2 {
      padding-bottom: 30px;
    }
    .price {
      position: absolute;
      top: 0;
      right: 15px;
      width: auto;
    }
    .discount {
      display: none;
    }
    .product-count {
      border-radius: 5px !important;
      margin-top: 40px;
      margin-right: -45px !important;
    }
    .bi-truck {
      display: none;
    }
    .review {padding-top: 50px;}
    #cart_count i {
      font-size: 25px;
  }
  }
  
  @media (max-width:767px) {
    .discount {
      display: block;
    }
    .sec-101 {
      position: absolute;
      top: 115px;
      left: 104px;
      right: 104px;
    }
    .bi-truck {
      display: none;
    }
    .product-count {
      border-radius: 20px !important;
      margin-top: 0;
    }
    .price {
      position: absolute;
      top: 0;
      right: 15px;
      width: auto;
    }
    .discount {
      position: absolute;
      right: 34px;
      top: 45px;
    }
    .price3 {
      position: absolute;
      top: 26px;
      right: 96px;
      width: auto;
    }
    .add_sec {
      position: relative;
    }
    .lt {
      padding-left: 0 !important;
    }
    .no_sec {
      width: 17.1px;
    }

    .notification{
      top:0px; width: 100%;
      left: 0px;
      border-radius: 0px 0px 5px 5px;
      position: fixed;
      margin: 0px 10px;
      width: 97%;
      z-index: 9999;
    }
    .notification p br{display: none;}
    .notification .icon i{transform: translate(0%, 50%);}



    .order-list .container{max-width: 100%;}
    .order-list .order-first .round{width: 55px; height: 55px; margin-right: 10px;}
    .order-list .action .price{position: relative;}
    .order-list .title{flex: 0 0 80%;}
    .order-list .title p.status{padding: 5px 15px;}
    .order-list .accordion-body .left, .order-list .accordion-body .right{flex:0 0 100%}
    .order-list .accordion-body p{font-size: 14px;}
    .order-list .price{font-size: 22px;}
    .order-list .title p.status{margin-bottom: 5px;}
    .logo.text-center{text-align: left !important;}
    .order-list .title p span{padding-right: 0px; margin-right: 5px;}
    /* .uktcn-franchise-dv{height: 130px;} */


    .front-header .logo{padding-bottom: 0px; padding-top: 0px;}
    .front-header .logo img{padding-top: 29px;}


    .sublogin{display: block;}
  .list{padding-bottom: 20px;}
  .fixedbar{bottom: 45px;}
  .fixedbar h3{display: none;}
  .fixedbar a{display: none;}
  .fixedbar div{flex: 0 0 100%;
    justify-content: center;}

    .review {padding-bottom: 90px !important;}
    .checkout .row{
      flex-direction: column-reverse;
    }

    .container-list.refresh-contaner.destination-table.container{
      max-width: 100%;
    }

    .one_sec{flex:0 0 15%; overflow: hidden; height: 23px;}
    .two_sec{flex:0 0 42%; overflow: hidden; height: 23px;}
    .three_sec{flex:0 0 20%; overflow: hidden; height: 23px;}
    .four_sec{flex:0 0 23%; overflow: hidden; height: 23px;}

    .list-head.destination-header .price2{flex:0 0 15%;}
    .list-head.destination-header .origin{flex:0 0 46%;}
    .list-head.destination-header .destination{flex:0 0 20%;}
    .list-head.destination-header .status{flex:0 0 23%;}
    
    .welcome-table .destination{flex: 0 0 56%;}
    .welcome-table .status { flex: 0 0 43%; }
    .destination-table .first_sec{margin-left: 0px;}
    .destination-table .status_color{margin-left: 0px;}
    .destination-table .two_sec{flex: 0 0 47%;}

    .destination-table .status_color ul li{width: 16px; height: 42px;}
    .destination-table .boarding .status_color ul li, .destination-table .delayed .status_color ul li, .destination-table .make_offer .status_color ul li, .destination-table .last_minutes .status_color ul li{
      background-size: 100% auto;
      background-position: center;
    }
    .container-list .list-head h4{font-size: 14px;}
    .container-list .list-head{padding: 15px 5px;}


    .searchresult.refresh-contaner .btn-text button{width: 100%;}
    .searchresult.refresh-contaner .btn-text p{text-align: center; margin-top: 10px;}

    .discount{position: relative; right: 0px; left:0px; width: 100%; top:0px;}
    .destination-table .three_sec{flex: 0 0 12%;}


    .accordion-header .accordion-button .mb-0.me-3{
      flex:0 0 80%;
      margin-right: 0px !important;
    }

  }
  
  @media (max-width:700px) {
    .price {
      position: absolute;
      top: 0;
      right: 15px;
      width: auto;
    }
    
    .width_productss {
      width: 100%;
    }
    .mobile-btn {
      position: absolute;
      top: 31px;
      right: 11px;
    }
    .pament_sec .text-warning {
      color: #fff !important;
    }
    .border-top {
      border-top: none !important;
    }
    .pament_sec {
      /* position: fixed;
      bottom: 0;
      background: #462364;
      width: 99%;
      padding-bottom: 10px;
      left: 0px;
      padding: 0 10px 10px 19px;
      border: none !important;
      z-index: 99999999999999;
      border-radius: 20px 20px 0 0; */
    }
    .sec-101 {
      position: absolute;
      top: 104px;
      left: 7px;
      right: 6px;
    }
    .navbar-toggler {
      /* margin-right: -20px; */
    }
    .in_block {
      width: 100%;
      display: inline-block;
      margin-top: 20px;
      font-size: 16px;
    }
    .fd::before {
      right: 52px;
    }
    .btn_mobile {
      position: absolute;
      right: 25px;
    }
    .bi-truck {
      display: none;
    }
    .no_sec h3 {
      font-size: 14px;
    }
    
    .no_sec {
      /* width: 13.5px; */
      /* height: 23px; */
      background-position: center;
    }
    .list-head h4{font-size: 16px;}
    .price3 {
      position: absolute;
      top: 26px;
      right: 96px;
      width: auto;
    }
    .add_sec {
      position: relative;
    }

    .destination-table .first_sec:nth-of-type(2) .no_sec:last-child ,
    .destination-table .first_sec:nth-of-type(2) .no_sec:nth-of-type(16),
    .destination-table .first_sec:nth-of-type(2) .no_sec:nth-of-type(15)
    { display: block;}
    #cart_count i{font-size: 25px;}
    .welcome-table .two-row-one{flex: 0 0 56%;}
  }
  
  
   @media (max-width:575px) {
    .order-list .order-first{display: none;}
    .order-list .title, .order-list .action{flex:0 0 100%;}
    .order-list .accordion-item{padding: 10px;}
    .order-list .price{font-size: 20px;}
    .order-list .title p.status{margin-bottom: 15px;}
    /* .uktcn-franchise-dv{height: 114px;} */

    .welcome-table .first_sec:nth-of-type(1) .no_sec:last-child{display: none;}
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(28), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(29), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(30), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(26)
    {display: block;}

    .order-list .title{padding-left: 0px;}
    .order-list .items{padding:15px;}
    
    .swal2-popup.swal2-modal.swal2-icon-success.swal2-show{
      width: 80%;
      margin:0 auto;
    }
    .swal2-title{
      font-size: 20px;
      color: #000;
    }

    .welcome-table .destination{flex: 0 0 56%;}
    .welcome-table .status { flex: 0 0 43%; }

    .order-list .title p.status{font-size: 12px; margin-left: 5px;}

    .destination-table .three_sec{flex: 0 0 15%;}
    .destination-table .four_sec {
      flex: 0 0 31%;
    }
    .destination-table .two_sec {
      flex: 0 0 35%;
    }
    .list-head.destination-header .origin {
      flex: 0 0 37%;
    }
    .accordion-header .accordion-button .mb-0.me-3{
      flex:0 0 70%;
    }
   }
   @media (max-width:560px) {
    .list-head h4 {
      font-size: 14px;
    }
    .no_sec h3 {
      font-size: 12px;
      font-weight: 400;
    }
   
    .no_sec {
      width: 12px;
    }
    .welcome-table .no_sec{width: 13px;}
    /* .welcome-table .destination{width: 73%;} */

    /* .destination-table .list-sec a .first_sec .no_sec:last-child{display: none;} */
    .fixedbar p{font-size: 10px; margin-left: 0px;}

    /* cart page */
    .order-list-header{display: none !important;}
    .order-list{border-radius: 10px;}
    .order-list .items .image-title{    flex: 0 0 50%; margin-bottom: 5px;}
    .order-list .items .price{flex: 0 0 50%; justify-content: flex-end; display: none;}
    .order-list .items .qty{flex: 0 0 50%; justify-content: flex-end;}
    .order-list .items .image-title h3{margin-bottom: 7px; }
    .order-list .items .total-price p{margin-bottom: 0px;}
    .order-list .items{padding-bottom: 10px;}
    .cart .order-list .items span{display: inline-block; width: auto;}
    .cart .order-list span{color: #a5a5a5; font-size: 14px; margin-right: 15px;}
    .order-list .items .total-price p{text-align: left;}
    .order-list .items .del-button button{padding: 0px; }
    .order-list .items .del-button{text-align: right;}
    .order-list .items .del-button button i{font-size: 18px;}
    .order-list .items .total-price{flex: 0 0 80%;}
    /* .order-list .items .total-price font{font-size: 14px;} */

    .welcome-table .two-row-one{flex: 0 0 51%;}
    .status_color ul li{
      width: 13px;
      height: 35px;
      
    }
    .boarding .status_color ul li, .delayed .status_color ul li{
      background-size: 100% auto;
      background-position: center;
    }
    
    .destination-table .status_color ul li{width: 12px;}
    .cart .order-list span{margin-left: 10px;}
    .notification .icon i{transform: translate(0%, 133%);}
  }
  @media (max-width:500px) {
    .product-count {
      border-radius: 32px !important;
    }
    .r_right {
      display: block;
      padding-top: 2px;
    }
    .price3 {
      position: absolute;
      top: 36px;
      right: 32px;
      width: auto;
    }
    .clint_img {
      display: none;
    }
    .no_sec h3 {
      font-size: 12px;
    }
    
    .no_sec {
      width: 11px;
      height: 19px;
    }
    

    /* .destination-table .first_sec .no_sec:nth-of-type(5n){display: none;} */
    .destination-table .first_sec:nth-of-type(2) .no_sec:nth-of-type(16), 
    .destination-table .first_sec:nth-of-type(2) .no_sec:nth-of-type(15),
    .destination-table .first_sec:nth-of-type(2) .no_sec:nth-of-type(14)
    { display: block;}
    .delivery_address.address_container .btn_mobile{font-size: 12px; padding: 5px 15px; right: 5px;}
    .delivery_address.address_container h5{margin-bottom: 30px !important;}
    .checkout .delivery_address div p{width: 100%; border-right: 0px;}
    #modal-body-lg{padding-left: 0px; padding-right: 0px;}
    .welcome-table .lt{padding-left: 0px !important;}
    .welcome-table .first_sec:first-child .no_sec:nth-of-type(15n){display: none;}
    .welcome-table .first_sec:first-child .no_sec:last-child{display: none;}

    .two_sec{    flex: 0 0 35%; height: 19px;}
    .four_sec{flex: 0 0 30%;}
    .list-head.destination-header .origin{flex: 0 0 35%;}
    .list-head.destination-header .status{flex: 0 0 30%;}
    
    .destination-table .two_sec{flex: 0 0 32%;}
  }
  
  
  @media only screen and (max-width:480px){
    /* .uktcn-franchise-dv {
      height: 84px;
    } */
    .owl-nav .owl-prev, .owl-nav .owl-next{top: 41% !important;}
    .welcome-table .first_sec:nth-of-type(1) .no_sec:last-child{display: none;}
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(28), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(29), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(30), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(26), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(28), 
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(27),
    .welcome-table .first_sec:nth-of-type(1) .no_sec:nth-of-type(25)
    {display: block;}

    .sublogin ul li a{font-size: 14px;}
    .container-list .list-head{overflow: hidden;}
    
    .revivew-given{flex-wrap: wrap;}
    .revivew-given p, .revivew-given .reviews{width: 100%;}
    .destination-table .no_sec{ flex:0 0 0;}
    .sublogin.addClass {height: 197px;}
    .order-list .items .image-title{flex:0 0 69%;}
    .order-list .items .qty{flex:0 0 30%;}
    .order-list .title p span{display: inline-block; width: auto;}
    .cart h3{font-size: 16px;}
    .payment-summery{margin-top: 20px;}

    .two-row-one, .two-row-two{height: 18px;}
    .two-row-one{flex: 0 0 67%;}
    .status {flex: 0 0 30%; }
    .destination { flex: 0 0 70%;}

    .four_sec, .three_sec, .two_sec, .one_sec{height: 18px;}
    .list-head h4{font-size: 12px;}
    
    footer p{font-size: 13px;}
    .heading h1{font-size: 22px; -webkit-text-stroke: 0px #ffbc07; font-weight: 400;}
    .mobile_footer{width:97%; margin-bottom: 10px;}
    .mobile_footer .mobile_footer_section a i{font-size: 16px;}
    .mobile_footer .mobile_footer_section a span{font-size: 10px; margin-top: -12px;}

    .container-list .list-head{padding: 10px 10px;}
    .container-list .list-head h4{font-size: 14px;}

    .destination-table.container-list .list-head h4{font-size: 12px;}
    .destination-table.container-list .list-head{padding: 10px 5px; margin-bottom: 10px; align-items: center;}
    .destination-table.container-list .list-head .status{text-align: right;}
    .list-head.destination-header .origin{flex: 0 0 65%;}
    .list-head.destination-header .status {
      flex: 0 0 14%;
    }
    .list-head.destination-header .destination{flex: 0 0 4%;}
    .destination-table .status_color ul li{width: 16px;}
    

    .bi-geo-alt-fill{
      top:60px;
    }
    
    .destination-table .no_sec{font-size: 16px;}

    .offer_image{width: 80px; height: 80px; flex:0 0 80px;}


    .destination-table .first_sec.four_sec{
      display: none;
    }
    
    
    .destination-table .no_sec{width: 13px;}
    .destination-table .status_color ul li{width: 15px;}
    .destination-table .one_sec{flex: 0 0 15%; margin-right: 5px;}
    .destination-table .two_sec{flex: 0 0 63%; margin-right: 5px;}
    .destination-table .three_sec{flex: 0 0 14%; margin-right: 5px;}
    .destination-table .four_sec{flex: 0 0 41%;}
    .destination-table .status_color ul li{width: 13px;}
    .list-head.destination-header .price2{flex: 0 0 17%;}

    .destination-table .status img{display: block; float: right; width: 20px;}
    .destination-table .status span{display: none;}

    .order-list .reviews a{font-size: 12px; padding: 5px 15px;}
  }
  @media (max-width: 400px) {
    .no_sec {
      width: 9px;
    }
    .logo {
      width: 68%;
    }
    .clint_img {
      display: none;
    }
    .fixedbar{display: none;}
    
    
    .destination-table .status_color ul li{width: 13px;}
  }
  @media (max-width: 365px) {
    .no_sec {
      width: 8px;
    }
    .btn-primary {
      width: 84px;
      padding: 0 0 !important;
    }
    .order-list .title p.status{
      width: auto;
      margin-left: 0px;

    }
    .order-list .title p span{width: 100%; padding-right: 0px; margin-right: 0px; border:0px; }
    .order-list .title p.status{margin-bottom: 15px; margin-top: 5px;}

    .two-row-one { flex: 0 0 62%; }
    .status { flex: 0 0 35%;}
  .destination {flex: 0 0 64%;}
  .no_sec h3{font-size: 9px;}
  .list-head h4{font-size: 10px;}
  #cart_count i{font-size: 20px;}
  .mobile_footer a img{width: 17px;}
  .mobile_footer #cart_counts p{line-height: 10px;}
 
  
  .destination-table .status_color ul li{width: 14px;}
  .destination-table.container-list .list-head h4{font-size: 10px;}
  .destination-table .one_sec{flex:0 0 22%;}
  .destination-table .two_sec{flex: 0 0 46%;}
  .destination-table .three_sec{flex: 0 0 18%;}
  .destination-table .list-sec{margin-bottom: -3px;}
  .destination-table .no_sec{width: 12px;}
  .destination-table .no_sec h3{font-size: 12px;}
  .offer_image{width: 90px; height: 80px; flex:0 0 90px;}
  /* .destination-table .boarding .status_color ul li, .destination-table .delayed .status_color ul li, .destination-table .status_color ul li{} */

  .destination-table .status_color ul li{
    width: 12px;
  }
  .list-head.destination-header .price2{flex: 0 0 24%;}
  .list-head.destination-header .origin{flex: 0 0 51%;}
  .list-head.destination-header .status {
    flex: 0 0 20%;
  }
  .revivew-given{text-align: center;}
  .order-list .reviews a{width: 100%; text-align: center; margin-top: 10px;}
  }
  @media only screen and (max-width:320px){
    .no_sec{width: 7.5px;}
    .list-head h4{font-size: 10px;}
    .list-head.destination-header .origin{flex: 0 0 35%;}
    .list-head.destination-header .ls{flex: 0 0 14%;
      text-align: right;}
      .offer_image{width: 90px; height: 70px; flex:0 0 90px;}
      .list-head.destination-header .origin{flex: 0 0 42%;}
      .list-head.destination-header .status{flex: 0 0 28%;}
  }
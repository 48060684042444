// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Font Awesome
@import "font-awesome/scss/font-awesome";

//Owl Carousel
@import 'owl.carousel/dist/assets/owl.carousel.css';

//theme css
@import "../css/app.css";
@import "../css/rs-spacing.css";
@import "../css/main.css";
@import "../css/custom.css";
@import "../css/responcive.css";
@import "../css/jquery-ui.css";
@import "../css/style-v2.css";

body{
	/* background: #000000; */
}
.bg-sec{
	padding-bottom: 7%;
	/* height: 100vh; */
}
.bg-sec:after{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #000000;
	top:0px;
	z-index: -1;
}
.custom_menu{
	padding-top: 15px;
	padding-bottom: 0px;
}
.custom_menu .bg-color{
	background: #2d2d2d;
	border-radius: 10px;
	margin:0px 0px;
}
.header_login{

}
a.btn_style{
	background: #f4b33f;
	border-radius: 7px;
	text-align: center;
	display: flex;
	align-items: center;
	padding: 9px 5px;
	color: #000000;
	text-align: center;
	justify-content: center;
	position: relative;
	font-weight: 600;
	border:1px solid #f4b33f;
}
a.btn_style:hover{
	background: transparent;
	color: #f4b33f;
}
.btn_style span{
	width: 30px;
	height: 30px;
	border-radius: 6px;
	background: #2d2d2d;
	display: flex;
	/*margin-right: 30px;*/
	align-items: center;
	justify-content: center;
	position: absolute;
	top:6px;
	left:5px;
}
.btn_style span i{
	font-size: 16px;
	color: #ffffff;
	padding: 6px;
}
.btn_style a{
	color: #000000;
	font-size: 14px;
	font-weight: 600;
}
.custom_menu .navbar .nav-link{
	font-size: 15px;
	color: #b6b6b6;
	padding: 7px 17px !important;
}
.custom_menu .navbar .active a.nav-link {
	color: #f4b33f ;
}
.header_heading h1{
	color: #f4b33f;
	font-weight: 600;
	font-size: 24px;
	margin-bottom:0px;
}
.header_sub_heading{
	margin:15px 0px;
}
.header_sub_heading h2{font-size: 30px; color: #ffffff; font-weight: 600; margin-bottom: 0px;}
.header_sub_heading h2 img{margin-right: 20px;}
.header_heading{
	margin-top:30px !important;
	margin-bottom: 30px;
}
.status_area{
	margin-top: 30px;
}
.status_area ul{list-style: none; display: flex; align-items: center; margin-bottom: 0px; padding: 0px;}
.status_area ul li{
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 3px;
	margin-right: 2px;
	/*width: calc(100% / 3);*/
	margin-bottom: 2px;
}
.status_area .green li{background-color: #4caf50;}
.status_area .green p span{color: #4caf50;}
.status_area .delay li{background-color: #ba2229;}
.status_area .delay p span{color: #ba2229;}
.status_area .landing li{background-color: #4caf50;}
.status_area .make_offer li{background-color: #f4b33f;}
.status_area .last_minite li{background-color: #654caf;}

.status_area h4{font-size: 16px; color: #fff; font-weight: 500;}
.status_area p{ color: #ffffff; font-size: 12px; }
.status_area .box{margin-right: 10px;}


/*toggle view*/
.food_details_toggle{
	background: #2d2d2d;
	border-radius: 10px;
	padding: 20px;
	margin:20px 0px;
}
.food_details_toggle_depeture{padding-left:15px; padding-right: 0px; flex:0 0 75%;}
.deperture_section{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.depeture_right ul{display: flex; align-items: center; justify-content: space-between; list-style: none; margin:0px; padding: 0px;}
.depeture_right ul li{text-align: center;}
.depeture_right ul li h4{margin-bottom: 0px;}
.depeture_right{ flex:0 0 50%; }
.depeture_left h3{ color: #ffffff; font-size: 16px; }
.depeture_left h4{ color: #ffffff; font-size: 16px; color: #ffff00; font-weight: 500;}
.depeture_left h4 img{margin-right: 10px; width: 20px;}
.depeture_left .title{font-size: 18px; font-weight: 500;}
.depeture_right ul li h4{font-size: 16px; color: #ffffff; font-weight: 500;}
.depeture_right ul li span{font-size: 12px; color: #b6b6b6;     line-height: 15px;
    display: inline-block;}
.depeture_right ul li:nth-child(2){flex:0 0 58%; position: relative;}
.depeture_right ul li:nth-child(2):before{
	content:'';
	position: absolute;
	width: 42%; top:10px; left:5px; border:1px dashed #b6b6b6;
}
.depeture_right ul li:nth-child(2):after{
	content:'';
	position: absolute;
	width: 42%; top:10px; right:5px; border:1px dashed #b6b6b6;
}
.depeture_right ul li i{color: #ffffff;}

.food_details h2{color: #ffffff; font-size: 24px; font-weight: 500;}
.food_details_price{display: flex; justify-content: space-between; align-items: center; margin-top: 25px;}
.food_details_price_left{display: flex; align-items: center;}
.food_details_price_left p{color: #ffffff; font-size: 14px; margin-right: 20px; margin-bottom: 0px;}
.food_details_price_left p span{color: #f4b33f; display: inline-block; margin-right: 10px;}
.food_details_price_left p:last-child{margin-right: 0px;}
.food_details_price a{flex:0 0 35%;}
.food_details_image.desktop_view{flex:0 0 200px; height: 200px; overflow: hidden; width: 200px;}
.food_details_image img{border-radius: 50%; border: 1px solid #f4b33f; height: 100%;}
.food_details_toggle .food_details_image img {width: 100%; }
.back{
	border-radius: 10px;
    border: 1px solid #F4B33F;
    padding: 10px 60px;
    color: #F4B33F;
    font-size: 16px;
    position: relative;
    transition:0.5s;
}
.back img{
	width: 29px;
    position: absolute;
    top: 17%;
    left: 5%;
    background: #000;
    padding: 7px;
    border-radius: 5px;
}
.back:hover{
	background: #F4B33F;
	color: #000000;
}
.header_pagination{margin-bottom: 25px;}
.header_pagination ul{ list-style: none; margin:0px; padding: 0px; display: flex; align-items: center;}
.header_pagination ul li{padding: 0px 17px;}
.header_pagination ul li:first-child{padding-left: 0px;}
.header_pagination ul li{font-size: 14px; color: #ffffff}
.header_pagination ul li a{color: #f4b33f; font-weight: 500;}
.header_pagination ul i{color: #ffffff;}
.mobile_view{display: none;}
.btn_group{flex:0 0 50%;}
.btn_group .cart_btn{display: none;}

/*login*/
.new_login_version .modal-content{ background: #2d2d2d; border-radius: 10px; }
.new_login_version .modal-header .btn-closes{ border:1px solid #f4b33f; box-shadow: 0px 0px 0px; border-radius: 5px !important; color: #f4b33f; opacity: 1; width: 45px; height: 45px; background: transparent;}
.new_login_version .modal-header .btn-closes img{width: 15px;}
.new_login_version .modal-header{ padding: 0px; border:0px; align-items: flex-start;}
.new_login_version .modal-title{color: #ffffff; font-weight: 500; font-size: 32px;}
.new_login_version .sub_heading{ color: #ffffff; font-size: 32px; color: #ffff00; font-weight: 500;}
.new_login_version .sub_heading img, .new_login_version .sub_heading i{ margin-right: 15px; width: 30px;}
.new_login_version .form-control{border-radius: 5px; background: #969696; border:1px solid #969696;}
.new_login_version .form-control::placeholder{color: #ffffff;}
.new_login_version label{color: #ffffff; font-size: 16px;}
.new_login_version .form-check.inputCheck{padding-left: 0px;}
.new_login_version .form-check.inputCheck input[type="checkbox"]{
	width: 20px;
	height: 20px;
	background-color: #b6b6b6 !important;
	border:0px;
	margin-right: 5px;
	margin-bottom: 0px;
}
.new_login_version .form-check.inputCheck .form-check-label{    position: relative;
    top: 0px;}
.new_login_version .submit{ background: #ffff00; color: #000000; border-radius: 5px; width: 100%; padding: 10px 0px; border: 1px solid #ffff00; position: relative; font-weight: 600;}
.new_login_version .submit span{
	width: 30px;
    height: 30px;
    border-radius: 6px;
    background: #2d2d2d;
    display: flex;
    /* margin-right: 30px; */
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 5px;
}
.new_login_version .submit span i{color: #ffffff;}
.new_login_version .submit:hover{
	background: transparent;
	color: #ffff00;
}
.new_login_version .login-social{display: flex; justify-content: space-between; list-style: none; padding: 0px; border-radius: 5px; margin-top: 20px;}
.new_login_version .login-social li{flex:0 0 49%; }
.new_login_version .login-social li a{width: 100%; padding: 10px 0px; display: inline-block; background: #ffffff; text-align: center; border-radius: 5px; font-size: 14px; color: #000000; box-shadow: none;}
.new_login_version .login-social li a.fb{
	background: #3b5998;
	color: #ffffff;
}
.new_login_version .login-social li a img{margin-right: 10px;}

.new_login_version .modal-dialog{    max-width: 590px;}
.new_login_version .regis p{color: #ffffff; margin:20px 0px; text-align: center;}
.new_login_version .register{width: 100%; border:1px solid #ffff00; background: #2d2d2d; color: #ffff00 !important; margin-bottom: 25px; font-weight: 500;}
.new_login_version .register:hover{
	background: #ffff00;
	color: #2d2d2d !important;
}
.new_login_version .reset{font-size: 14px; color: #ffffff; line-height: 29px;}
.new_login_version{}


/*product buldle*/
.bg-color{
	background: #2d2d2d;
	border-radius: 10px;
	margin:0px 0px;
}
.product_bundle .food_details_price{flex-wrap: wrap;}
.product_bundle .food_details_price_left{flex: 0 0 100%;}
.product_bundle .with_cart{justify-content: space-between; margin-top: 10px;}
.product_bundle .owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled{display: block;}
#foodSlider{margin:10px 0px; margin-bottom: 20px;}
#foodSlider .image_padding{padding: 20px 70px;}
#foodSlider .image_padding .image{height: 300px; display: flex; align-items: center; width: 300px; margin:0 auto;}
#foodSlider .image_padding .image img{border-radius: 50%; border:1px solid #F4B33F; height: 100%;}
.product_bundle .owl-carousel .owl-next, .product_bundle .owl-carousel .owl-prev{
	background: transparent;
	border-radius: 0px;
	text-indent: -999999px;
	box-shadow: none;
	background: transparent url(../images/owl-arrow-icon.svg) no-repeat center !important;
	-webkit-background-size: 30px auto ;
	background-size: 30px auto ;
}
.product_bundle .owl-prev::before, .product_bundle .owl-next::before{display: none;}
.food_details_price .food_details_price_left.with_cart a{flex:0 0 40%;}
.product_bundle .owl-carousel .owl-nav{top: 16%;
    position: absolute;
    width: 90%; left: 0px; right: 0px; margin:0 auto;}
.product_bundle .owl-carousel .owl-prev{transform: rotate(180deg);}
.product_bundle .owl-dots{display: flex !important; justify-content: center;}
.product_bundle .owl-dots .owl-dot{width: 10px; height: 10px; border-radius: 50px; background: #b6b6b6; margin-right: 5px;}
.product_bundle .owl-dots .owl-dot:last-child{margin-right: 0px;}
.product_bundle .owl-dots .owl-dot.active{
	background: #ffffff;
}

/*.product_bundle .owl-theam{position: relative;}*/
#foodSlider{overflow-x: hidden;}

.listen_latest_reviews{margin-bottom: 20px; margin-top: 20px;}
.product_heading{margin-bottom: 10px;}
.product_heading h2{color: #ffffff; display: flex; align-items: center; margin-bottom: 0px;}
.product_heading h2 i, .product_heading h2 img{color: #f4b33f; font-size: 20px; margin-right: 15px;}
.product_heading h2 img{width: 25px;}
.listen_latest_reviews p{color: #ffffff;}
.play_all_rewiews{padding: 0px; margin:0px; list-style: none;}
.play_all_rewiews li{margin-bottom: 10px; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;}
.play_all_rewiews li:last-child{margin-bottom: 0px;}
.play_all_rewiews .rewiew_person{
	background: #000000;
	border-radius: 10px;
	flex:0 0 25%;
	padding: 15px;
}
.play_all_rewiews .date{margin-bottom: 0px; text-transform: capitalize;}
.play_all_rewiews .rewiew_person p{margin-bottom: 0px;}
.play_all_rewiews .play_rewiews{background: #f4b33f; flex:0 0 74%;     min-height: 83px;
    height: 100%; border-radius: 10px; display: flex; justify-content:center; align-items: center; padding-left: 15px;}
.play_rewiews audio::-webkit-media-controls-enclosure {
    border-radius: 5px;
	background-color: #f4b33f;
}
.play_rewiews audio::-moz-range-track{
	border-radius: 5px !important;
	background-color: #f4b33f !important;
}
.play_rewiews audio::-moz-range-thumb{
	border-radius: 5px !important;
	background-color: #f4b33f !important;
}

.play_rewiews audio{
	width: 100%;
	border-radius: 0px;
	border-radius: 5px !important;
	background-color: #f4b33f !important;
}
.play_all_rewiews .play_rewiews p{
	font-size: 16px;
	color: #000000;
	margin-bottom: 0px;
	font-weight: 600;
}
.comboo_foods .food_details_toggle_depeture{
	margin-left: auto;
    margin-right: auto;
}
.ingredients{
	margin-top: 40px !important;
}
.comboo_foods .food_details_toggle{margin-top: 0px;}
.ingredients ul{padding: 0px; list-style: none;}
.ingredients heading h2{font-weight: 600; color: #ffffff; font-size: 30px;}
.ingredients heading h2 img{width: 30px; margin-right: 15px;}
.ingredients .seperator{ position: relative; margin: 30px 0px; }
.ingredients .seperator:after{
	content: '';
    position: absolute;
    width: 100%;
    top: 18px;
    left: 0px;
    border: 1px dashed #b6b6b6;
    z-index: 0;
}
.ingredients .seperator img{width: 38px;
    z-index: 1;
    position: relative;}
.ingredients .seperator p{font-weight: 500; color: #ffffff; font-size: 16px; margin-top: 20px; margin-bottom: 0px;}



/*food details*/
.food_details_bid h3{font-size: 16px; color: #ffffff; font-weight: 400;}
.food_details_bid h3 img{width: 20px; margin-right: 10px;}
.food_details_bid{ margin-top: 10px; }
.food_details_bid p{color: #ffffff; font-size: 16px;}
.price_range{flex: 0 0 50%; }
.price_range form{display: flex; justify-content: space-between; width: 100%;}
.price_range input[type="range"]{margin:0px 20px;}
.ingradient_list ul{list-style: none; padding: 0px; flex-wrap: wrap;}
.ingradient_list ul li{background: #f4b33f; border-radius: 35px; margin-right: 10px; padding: 5px 20px; font-weight: 500; margin-bottom: 10px;}
.ingradient_list p{color: #fff; font-size: 15px; font-weight: 300;}
.ingradient_list{margin-bottom: 20px;}

.all_reviews_audio{margin-top: 30px; margin-bottom: 40px;}
.all_reviews_audio .play_audio_review{border-radius: 10px;background: #000000; min-height: 80px;}
.all_reviews_audio p{color: #ffffff; font-size: 14px; font-weight: 300;}
.all_food_information{margin-top: 70px;}
.all_food_information .product_heading{margin-bottom: 50px;}
.all_food_information_list{
	background: #2d2d2d;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}
.all_food_information_list ul{list-style: none; padding: 0px;}
.all_food_information_list ul li{width: 30px; margin-right: 10px; height: 30px; overflow:hidden; margin-bottom: 10px;}
.all_food_information_list ul li img{width: 100%; position: relative;}
.all_food_information_list ul li:last-child{margin-right: 0px;}
.all_food_information_list ul li.active img{bottom: 30px;}

.all_food_information_list .product_heading{margin-bottom: 20px;}
.all_food_information_01 p{color: #fff; font-size: 15px; font-weight: 300;}
.product_details .image_padding{padding: 20px;}




/* range */
.value-indicator{transform: scale(1) !important;}
.current-time, .duration{color: #fff;}
.ball{border:2px solid #ffffff;}
.slider{width: 370px !important;}
.progressbar{
	position: relative;
	z-index: 0;
}
.progressbar:after{
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: #b6b6b6;
	z-index: -1;
	top:0px;
	left:0px;
}

.modal-body{padding: 1rem 0rem !important;}
.food_details_text h2 a{color: #fff;}
.food_details_text h2 img{width: 24px; margin-right: 10px;}
.ingredients .food_details_image{
	flex:0 0 240px;
	height: 240px;
	width: 240px;
}
.ingredients .food_details_image img{
	width: 100%;
	height: 100%;
}
.product_details .image_padding .image{
	width: 300px;
	height: 300px;
}
.product_details .image_padding .image img{
	border-radius: 50%;
	border: 1px solid #f4b33f;
	height: 100%;
	width: 100%;
}
.product_bundle .row.bg-color, .comboo_foods .row.food_details_toggle{margin: 0px 0px !important;}
.mobile_version.status_area{margin-top: 0px; display: flex; margin-right: 0px; margin-left: 15px;}

.mobile_version.status_area p{margin-bottom: 0px;}
.mobile_version.status_area > div:last-child{margin-left: 15px;}
.desination_area p{margin-bottom: 0px;}



/* inner page */
.new_about_ui h3{ font-size: 24px; color: #fff; margin-bottom: 20px;}
.new_about_ui p{ font-size: 16px; color: #fff; margin-bottom: 20px;}
.new_about_ui .card{ background-color: #2d2d2d; padding: 30px; border-radius: 10px;}
.new_about_ui .row{ margin-bottom: 70px; position: relative;}
.new_about_ui .card p{margin-bottom: 0px; line-height: 30px;}
.new_about_ui .card p span{color: #F4B33F;}
.new_about_ui .p-ab{ position: absolute; left: 130px; top: -65px;}
.About_form label{ width: 100%; color: #fff; display: inline-block; margin-bottom: 10px;}
.About_form input{ background: #979797; height: 40px; border-radius: 5px !important; box-sizing: border-box; padding-left: 20px;}
.About_form input::placeholder{color: #fff;}
.About_form .input-group{margin-bottom: 15px;}
.About_form h3{
	margin-bottom: 50px;
	color: #fff;
}
.About_form .row{
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
}
.team ul{list-style: none;}
.team ul li{padding:0px 15px; width: calc(100% / 5);}
.team ul li .image img {
	width: 100%;
border: 4px solid #f4b33f;
border-radius: 35px;
}
.team .teams .image{ position: relative; margin-bottom: 15px; width: 52%;
    margin: 0 auto 10px;}
.team .teams p{color: #fff; font-size: 16px; margin-bottom: 0px;}
.team .teams .image ul{
	position: absolute;
	right: 10px;
	bottom: 10px;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.team .teams p.deg{
	color: #F4B33F;
	font-size: 16px;
}
.team .col-lg-12.d-flex{
	flex-wrap: wrap;
}

.team ul li .image ul li{
	flex:auto;
	padding: 0px;
	margin: 0px;
	margin-right: 5px;
}
.team ul li .image ul li img{
	border:0px;
	border-radius: 0px;
}
.social{
	padding: 0px;
	margin: 0px;
	padding-bottom: 30px;
	margin-bottom: 0px;
}
.social li a{
	padding: 0px 20px;
	color: #ffffff;
	display: inline-block;
	font-size: 17px;
}
.social li:last-child a{
	padding-right: 0px;
}
.social-icons-ql{
	width: 100%;
}

.image_gallery{
	padding-bottom: 50px;
}
.image_gallery .section{
	width: 100%;
	position: relative;
	margin-bottom: 30px;
}
.image_gallery .section img{
	width: 100%;
}
.image_gallery .section h3{
	color: #fff;
	font-size: 20px;
	font-weight: 300;
}
.image_gallery .section .d-flex{
	position: absolute;
	left: 0px;
	right:0px;
	width: 100%;
	padding: 10px;
	bottom: 0px;
}
.fa.fa-thumb-tack{
	color: #f4b33f;
    transform: rotate(45deg);
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 18px;
}

#slider{
	width: 70%;
	height: 4px;
	position: relative;
}
#slider::before{
	content: '';
	position: absolute;
	width: 17px;
	height: 17px;
	background: #fff;
	border-radius: 25px;
	top: -9px;
    left: -5px;
}
#slider:after{
	content: '';
	position: absolute;
	width: 17px;
	height: 17px;
	background: #fff;
	border-radius: 25px;
	top: -9px;
    right: -14px;
}
.next_data{
	padding-left: 25px;
}
.ui-state-default, .ui-widget-content .ui-state-default{
	background: #f4b33f;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    top: -18px;
    text-align: center;
    padding: 5px 0px;
    font-weight: 600;
    color: #fff;

}
.social-icons-ql{
	margin-top: 12px;
}


.privacy-policy p, .privacy-policy li, .privacy-policy td{color: #fff; font-size: 16px;}
.privacy-policy h3{color: #F4B33F; font-size: 20px; margin:40px 0px;}
.privacy-policy h4{color: #fff; font-weight: 400;}
.privacy-policy table thead th{color: #F4B33F; font-size: 16px; font-weight: 400;}
.privacy-policy table td{  font-size: 14px;  border:0px;}
.privacy-policy table tr td{outline: 1px solid rgba(255,255,255,0.3);}
.privacy-policy table tr td:first-child{outline: 1px solid rgba(255,255,255,0.3);}

.privacy-policy table tr{border-left: 4px solid rgba(255,255,255,0.3); border-right: 1px solid rgba(255,255,255,0.3);}
.privacy-policy table thead tr:first-child{border:0px;}
.privacy-policy table{width: 99.9%;}
.login:focus, .login:active{
	outline:none !important;
}
.rewiew_person .for_mobile{display: none !important; margin-bottom: 0px;}

/*responsive*/
@media only screen and (max-width: 1280px){
	.header_sub_heading h2{font-size: 24px;}
}
@media only screen and (max-width: 1024px){
	.ingredients .food_details_image{height: 200px;}
	.product_details .image_padding .image{width: 200px; height: 200px;}
	.header_sub_heading h2{font-size: 24px;}
	.play_all_rewiews .rewiew_person p{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}
	.play_all_rewiews .rewiew_person{
		width: 25%;
		padding: 12px 10px;
	}
	.play_all_rewiews .play_rewiews{
		width: 73%;
	}

}
@media only screen and (max-width: 991px){
	.custom_menu .bg-color{background: transparent; position: relative; width: 100%;}
	.header_login{ display: none; }

	.header_heading{margin-top: 0px; position: relative; z-index: 2; margin-top: 15px !important; margin-bottom: 15px;}
	.navbar{justify-content: flex-end;
		z-index: 0;}
	.header_logo{margin-bottom: 25px; display: inline-block;}
	.header_sub_heading h2{font-size: 24px;}
	.header_heading h1{font-size: 20px; margin-bottom: 25px;}
	.mobile_view{display: block;}
	.desktop_view{display: none;}
	.deperture_section .depeture_left{width: 100%; display: flex; align-items: center; justify-content: space-between;}
	.food_details{display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;}
	.depeture_right.mobile_view{flex:0 0 100%; margin-top: 20px;}
	.food_details_image.mobile_view{text-align: center;}
	.food_details_text{flex:0 0 55%;}

	.food_details_price{flex-wrap: wrap;}
	.food_details_price_left{ flex:0 0 100%;display: flex; justify-content: space-between; margin-bottom: 20px; }
	.btn_group .cart_btn{display: block}
	.btn_group{flex:0 0 100%; display: flex; justify-content: space-between;}
	.btn_group a{flex:0 0 49%;}
	.btn_group .discover{background: #2d2d2d; color: #f4b33f; flex:0 0 100%;}
	.food_details_toggle{padding: 15px 0px;}
	.depeture_right ul li:nth-child(2){flex:0 0 70%;}

	.custom_menu .navbar-toggler{ float: right; z-index: 9999; position: relative;}
	.custom_menu .navbar-toggler i{font-size: 20px; color: #ffffff;}
	.custom_menu{position: absolute; width: 20%; z-index: 99; right: 0px; padding-top: 0px;}
	/* .header_logo{margin-top: 20px;} */


	/*product bundal*/
	.product_bundle .food_details_text{flex:0 0 100%; overflow: hidden;}
	.product_bundle .food_details_price_left{margin-bottom: 5px;}
	#foodSlider{margin-bottom: 30px;}
	#foodSlider .image_padding {padding-top:0px;}
	.deperture_section.mobile_view{padding-top: 20px;}
	.comboo_foods .food_details_text{flex:0 0 55%; overflow: hidden;}
	.product_bundle .food_details_price_left.with_cart{flex-wrap: wrap;}
	.product_bundle .food_details_price_left.with_cart .btn_style{flex:0 0 100%;}
	.product_bundle .food_details_price_left.with_cart p{flex:0 0 100%; margin-right: 0px;}


	/*product details*/
	.product_bundle .food_details_price_left.with_cart .price_range p{flex:0 0 0;}
	.price_range{flex:0 0 100%; margin-bottom: 20px;}
	.product_bundle .food_details_price_left.with_cart{margin-bottom: 30px;}
	.all_food_information_list .all_food_information_01 ul{flex-wrap: wrap;}
	.all_food_information_list ul li{margin-bottom: 5px;}
	.ingradient_list ul li{margin-bottom: 5px;}
	.all_food_information .product_heading{margin-bottom: 20px;}
	.all_food_information{margin-top: 20px;}

	/* bug css */
	.custom_menu .navbar{justify-content: flex-end;}
	.navbar-toggler img{width: 21px; height: 21px;}
	.navbar-toggler img.close{margin-top: 0px;}
	.navbar-toggler .close{display: none;}
	.navbar-toggler.bi-x .close{display: block;}
	.navbar-toggler.bi-x .menu_icon{display: none;}
	.navbar-toggler .bi-list .menu_icon{display: block;}
	.navbar-toggler .bi-list .close{display: none;}
	.food_details_toggle_depeture{padding-right: 15px;}

	#cart_count{position: absolute; right: 43px;
		top: 8px;}

	.header_pagination ul.food_pagination li{ padding: 0px 5px;}
	.header_pagination ul.food_pagination li a, .header_pagination ul.food_pagination li{font-size: 11px;}

	.food_details_toggle{margin: 5px 0px;}
	.destination-table .list-sec{margin-bottom: 2px;}
	.food_details_image.mobile_view{height: 264px; flex:0 0 264px; width: 264px;}
	.product_details .image_padding .image{height: 195px; width: 195px;}


	.food_details_toggle .food_details_text p{
		max-height: 91px;
		overflow: hidden;
	}
	.new_about_ui .p-ab{
		left: inherit;
		top: inherit;
		bottom: -220px;
	}
	.team ul li{
		flex:calc(100% / 2);
		margin-bottom: 30px;
	}
	.team ul li:last-child{
		flex:0 0 100%;
		margin-bottom: 0px;
	}
	.team .teams .image{
		width: 100%;
	}
	.team ul li .image img{
		width: 100%;
	}
	.social li:first-child a{
		padding-left: 0px;
	}
	.team .teams .image ul img{
		width: inherit;
	}
	.team .teams .image ul li{
		margin-bottom: 0px;
		flex: auto;
	}
	.About_form label{min-height: 45px;}

	.frozen_food{
		padding-top: 23%;
	}
	.how-it-works{
		overflow-y: hidden;
	}

	.row1 .col-lg-7::after{
		height: 386px;
		/* transform: rotate(42deg); */
		top: 140px;
		z-index: 0;
		right: 0px;
		left: inherit;
		background: url(../images/m-line05.png) no-repeat !important;
		width: 100%;
		background-position: top right !important;
		background-size: auto 100% !important;
	}
	.row2 .col-lg-7::after{
		height: 400px;
		background-size: auto 100%;
		left: -10%;
		background: url(../images/m-line01.png) no-repeat !important;
		width: 100%;
		background-position: top left !important;
		background-size: auto 100% !important;
		top: 53%;

	}
	.row3 .col-lg-7::after{
		height: 538px;
		top: 150px;
		transform: rotate(0deg);
		left: inherit;
		right: 0px;
		background: url(../images/m-line02.png) no-repeat !important;
		width: 100%;
		background-position: top right !important;
		background-size: auto 100% !important;
	}
	.how-it-works .row {
		padding-bottom: 20px;
	  }
	.row4 .col-lg-7::after{
		left:0%;
		height: 450px;
		top: 92%;
		background: url(../images/m-line03.png) no-repeat !important;
		width: 100%;
		background-position: top left !important;
		background-size: auto 100% !important;
	}
	.row5 .col-lg-7::after{
		width: 100%;
		height: 450px;
		background: url(../images/m-line04.png) no-repeat !important;
		width: 100%;
		left:0%;
		background-position: top right !important;
		background-size: auto 100% !important;
		top: 95%;
	}
	.depeture_right ul{align-items: flex-start;}
	.new_about_ui .row{margin-bottom: 30px;}

	.ui_slider p{    flex: 0 0 6% !important;}
    .ui_slider{margin-bottom: 30px;}
    .rewiew_person .for_mobile{display: block !important;}
    .rewiew_person ul{display: none !important;}
}
@media only screen and (max-width: 767px){
	.new_login_version .modal-content{width: 94%; margin:0 auto;}

	.play_all_rewiews .play_rewiews{flex: 0 0 60%;}
	.ingredients heading h2{font-size: 25px;}
	.status_area ul li{width: 12px; height: 12px; border-radius: 2px;}
	.status_area p{font-size: 10px;}
	.status_area h4{font-size: 14px; margin-bottom: 2px;}
	#cart_count{display: none;}

	.bg-sec{padding-bottom: 14%;}
	.status_area ul li:first-child{display: none}
	.status_area ul li:last-child{display: none}

	.desination_area.status_area ul li:first-child{display: block}
	.desination_area.status_area ul li:last-child{display: block}


	.product_bundle .food_details_toggle_depeture{flex: 0 0 100%;}
	.food_details_image.mobile_view{height: 214px; width: 214px; flex:0 0 40%;}

	.product_details .image_padding{ padding-top: 0px;}
	.product_details .image_padding .image{margin: 0 auto;}
	.product_details .image_padding .image{ width: 150px; height: 150px;}

	.back{border:0px; padding: 0px; text-indent: -9999999px; color: #000000;}
	.back img{border: 1px solid #f4b33f; position: relative; top:0%; left:inherit; right: 0px;}
	.back:hover{background-color: transparent;}
	.back span{display: none;}

	.custom_menu .navbar-toggler{top:-9px;}
	.header_heading{margin-bottom: 0px;}

	.team ul li{
		flex:calc(100% / 2);
		margin-bottom: 30px;
	}
	.team ul li:last-child{
		margin-bottom: 0px;
	}
	.About_form label{min-height: inherit;}
	.business{
		padding-bottom: 25%;
    }

    .play_all_rewiews .rewiew_person{
        flex: 0 0 38%;
        width: 38%;
    }
}
@media only screen and (max-width: 575px){
	/* .back{padding: 10px 40px;} */
	.header_sub_heading h2{font-size: 20px;}
	.header_heading h1{font-size: 18px;}

	/* .custom_menu .navbar-toggler{right: 15px;} */
	.container.status_area{margin-bottom: 10%;}

	.new_login_version .modal-header .btn-closes{width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;}
	.new_login_version .modal-header .btn-closes img{width: 12px;}


	.welcome-table .status_color ul li:last-child{display: none}
	.welcome-table .status_color ul li:first-child{ display: none}
	.food_details_image.mobile_view{height: 170px; flex:0 0 170px}

	.all_food_information_01 p{font-size: 12px;}

	#foodSlider .image_padding .image{width: 300px; height: 300px; margin:0 auto;}
	#foodSlider{margin-bottom: 0px;}
	.product_bundle .owl-carousel .owl-nav{width: 97%; top:6%;}
	#foodSlider .image_padding{padding-left: 0px; padding-right: 0px;}

	.food_details .food_details_text p{font-size: 12px;}
	.product_details .image_padding .image{ width: 200px; height: 200px;}
	.food_details_toggle .food_details_text p{
		max-height: 81px;
		overflow: hidden;
	}
	.ingredients, .all_food_information{margin-bottom: 10%;}
	.status_area .box{margin-right: 2px; margin-top: 0px;}
	.header_logo{margin-bottom: 10px;}
	.status_area.desination_area{margin-top: 15px;}

	.new_about_ui h3{font-size: 20px; font-weight: 300;}
	.inner-heading h2{font-size: 28px;}
	.frozen_food{
		padding-top: 30%;
	}
}
@media only screen and (max-width: 480px){
	.header_sub_heading h2{font-size: 17px;}
	.header_heading h1{font-size: 16px;}

	.header_pagination ul li{padding: 0px 12px; font-size: 12px;}

	.new_login_version .modal-content{width: 97%;}

	.ingredients heading h2{font-size: 22px;}

	.product_bundle .food_details_price_left{flex-wrap: wrap; margin-bottom: 0px;}
	.product_bundle .food_details_price_left p{flex:0 0 100%; margin-right: 0px; margin-bottom: 10px; display: flex; justify-content: space-between;}
	.product_bundle .food_details_price_left.with_cart{margin-top: 0px;}
	.product_bundle .food_details_price_left.with_cart .btn_style{margin-top: 20px;}

	#modal-body{padding-left: 0px; padding-right: 0px;}
	.new_login_version .login-social{flex-wrap: wrap;}
	.new_login_version .login-social li{flex: 0 0 100%;}
	.new_login_version .login-social li:last-child{margin-top: 5px;}

	.new_login_version .modal-content{padding: 10px;}
	.new_login_version .modal-title, .new_login_version .sub_heading{font-size: 25px;}
	.depeture_left .title, .depeture_left h4{font-size: 14px;}
	.depeture_left h4 img{width: 20px; margin-right: 4px;}
	.food_details h2{font-size: 18px;}
	.food_details_text p{
		width: 100%;
		/* overflow: hidden;  */
		/* text-overflow: ellipsis;  */
		/* white-space: nowrap; */
	}

	.header_pagination ul i{font-size: 10px;}
	.header_pagination ul.food_pagination li a, .header_pagination ul.food_pagination li{font-size: 10px;}

	.destination-table .food_details_text, .product_details .food_details_text{overflow: hidden;}
	.food_details_image.mobile_view{height: 120px; flex: 0 0 120px; width: 120px; margin-bottom: 0px;}
	#foodSlider .image_padding .image{width: 230px; height: 230px; margin:0 auto;}

	.product_bundle .owl-carousel .owl-nav{top: 36%;}

	.frozen_food{
		padding-top: 45%;
	}
	.depeture_right ul li:nth-child(2){
		flex: 0 0 58%;
	}
	.play_all_rewiews .play_rewiews p{display: none;}
	.play_all_rewiews .play_rewiews{padding-left: 0px;}
}
@media only screen and (max-width: 365px){
	.status_area.desination_area p{display: none !important;}
	.status_area.desination_area li{width: 14px; height: 17px;}
	.status_area.desination_area h4{font-size: 15px; line-height: 17px;}
	.play_all_rewiews .rewiew_person, .play_all_rewiews .play_rewiews{flex:0 0 100%;}
	.play_all_rewiews .rewiew_person{border-radius: 10px 10px 0px 0px;}
	.play_all_rewiews .play_rewiews{border-radius: 0px 0px 10px 10px;}

	.team ul li{
		flex:calc(100% / 1);

	}
}
@media only screen and (max-width: 360px){
	.back{padding-right: 0px;}
	.new_login_version .modal-content{width: 98%;}
	.btn_group a{flex:0 0 100%;}
	.btn_group{flex-wrap: wrap;}
	.btn_group .cart_btn{margin-bottom: 7px;}

	.new_login_version label{font-size: 14px;}
	#modal-body .mb-3 .pass-show-hide{top: 32px;}
	.food_details_image.mobile_view, .food_details_text{flex:0 0 100%;}
	.food_details_text p{margin-bottom: 0px;}

	.comboo_foods .food_details_text, .food_details_image.mobile_view{flex:0 0 100%; }
	.food_details_image.mobile_view{height: 250px; flex:0 0 250px; margin: 0 auto 20px;}
	.destination-table .status_color ul li{width: 12px !important;}

	#foodSlider .image_padding .image{width: 200px; height: 200px;}
	.depeture_right ul li:nth-child(2) {
		flex: 0 0 50%;
	  }
}
@media only screen and (max-width: 320px){

}

.copy_right {
	background: #464545;
	padding: 6px 0px;
}

.play_all_rewiews .rewiew_person{
	background: #000000;
	border-radius: 10px;
	/* flex:0 0 25%; */
	padding: 15px;
	/* width: 25%; */
}
.play_all_rewiews .rewiew_person span{

	display: inline-block;
	margin-right: 15px;
}
.rating_star i{color: #f4b33f; font-size: 14px;}
.rating_star li{margin-bottom: 0px;}
.rating_star ul{padding: 0px;}
.rating_star li a{margin-right: 5px;}
.play_all_rewiews .date{margin-bottom: 0px !important; text-transform: capitalize; font-size: 13px;}
.play_all_rewiews .rewiew_person p{margin-bottom: 0px; width: 100%; text-overflow: ellipsis; overflow: hidden; display: inline-block; white-space: nowrap;}
.play_all_rewiews .date{text-align: right;}
